import { Container, Row, Col, Form } from "react-bootstrap";

import Billing_icon from "../../images/icons/billing_icon.svg";
import useDeviceWidthChecker from "../customHooks/DeviceWidthDetector";

const BillingHeader = ({ Pagename }) => {
  const screenWidth = useDeviceWidthChecker();

  const pageHeaderstyle = {
    boxShadow: "0px 3px 5px 0px #00000014",
    backgroundColor: "#fff",
    padding: "25px 0px",
  };

  const pageHeadingstyle = {
    fontWeight: "700",
    fontSize: "22px",
    color: "#000",
    lineHeight: "30px",
  };

  const mobformstyle = {
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    height: "38px",
    lineHeight: "35px",
  };

  return (
    <>
      <section style={pageHeaderstyle} className="page-header">
        <div style={{ margin: `0 ${screenWidth > 1024 ? "125px" : "30px"}` }}>
          <Row className="align-items-center">
            <Col
              md={6}
              lg={8}
              xxl={9}
              style={pageHeadingstyle}
              className="page-heading"
            >
              <div
                className="flex align-items-center gap-2"
                style={{
                  fontWeight: "600",
                  fontSize: "22px",
                  fontFamily: "Segoe UI",
                }}
              >
                <img src={Billing_icon} alt="integration-icon" /> {Pagename}
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default BillingHeader;
