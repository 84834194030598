import axios from "axios";
import config from "../../config";

const baseUrl = `${config.backend}company/conversation/`;

export const getBillingDetail = async (startDate, endDate, timePeriod) => {
  const params = new URLSearchParams({ startDate, endDate, timePeriod });
  const response = await axios.get(
    `${baseUrl}detail?${params}
        `
  );
  return response.data;
};
