import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  parents: {}, // Stores state for each parent by id
  templatePreviewHeight: 0,
  nodes: [],
};

export const WorkflowSlice = createSlice({
  name: "Workflow",
  initialState,
  reducers: {
    setShowImage: (state, action) => {
      const { id, value } = action.payload;
      if (!state.parents[id]) state.parents[id] = {}; // Initialize if not exists
      state.parents[id].showImage = value;
      state.parents[id].showVideo = false; // Ensure others are false
      state.parents[id].showDocument = false;
    },
    setShowVideo: (state, action) => {
      const { id, value } = action.payload;
      if (!state.parents[id]) state.parents[id] = {}; // Initialize if not exists
      state.parents[id].showImage = false; // Ensure others are false
      state.parents[id].showVideo = value;
      state.parents[id].showDocument = false;
    },
    setShowDocument: (state, action) => {
      const { id, value } = action.payload;
      if (!state.parents[id]) state.parents[id] = {}; // Initialize if not exists
      state.parents[id].showImage = false; // Ensure others are false
      state.parents[id].showVideo = false;
      state.parents[id].showDocument = value;
    },
    setShowMessage: (state, action) => {
      const { id, value } = action.payload;
      if (!state.parents[id]) state.parents[id] = {}; // Initialize if not exists

      state.parents[id].showMessage = value;
    },
    setTemplatePreviewHeight: (state, action) => {
      state.templatePreviewHeight = action.payload;
    },
    setGLobalNodes: (state, action) => {
      state.nodes = action.payload;
    },
  },
});

export const {
  setShowImage,
  setShowVideo,
  setShowDocument,
  setShowMessage,
  setTemplatePreviewHeight,
  setGLobalNodes,
} = WorkflowSlice.actions;

export default WorkflowSlice.reducer;
