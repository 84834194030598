import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import Header from "../components/billing-insights/Header";
import Card from "../components/billing-insights/Card";
import Card1Content from "../components/billing-insights/Card1Content";
import useDeviceWidthChecker from "../components/customHooks/DeviceWidthDetector";
import Card2Content from "../components/billing-insights/Card2Content";
import BillingTable from "../components/billing-insights/BillingTable";
import { getBillingDetail } from "../services/Billing";
import { fromJSON } from "postcss";
import {
  setBillingData,
  setCurrency,
  setIsMonthSelected,
  setLoading,
} from "../reduxSlice/billing";
import SkeletonLoader from "../components/billing-insights/SkeletonLoader";

dayjs.extend(customParseFormat);

const rangePresets = [
  { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
  { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
  { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
  { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
];

const BillingInsights = () => {
  const screenWidth = useDeviceWidthChecker();
  const dispatch = useDispatch();
  const { loading, billingData, isMonthSelected } = useSelector(
    (state) => state.BillingReducer
  );

  const [selectValue, setSelectValue] = useState("daily");

  const [defaultStartDate, setDefaultStartDate] = useState("01/01/2021");
  const [defaultEndDate, setDefaultEndDate] = useState(new Date());
  const [rangePickerValue, setRangePickerValue] = useState(null);
  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");

  const getBilling = async (startDate, endDate, isMonthSelected) => {
    dispatch(setLoading(true));
    const resp = await getBillingDetail(startDate, endDate, isMonthSelected);
    dispatch(setLoading(false));

    const data = resp?.data?.conversation_analytics?.data[0]?.data_points;
    dispatch(setBillingData(data));
    dispatch(setCurrency(resp?.currency));
  };

  const handleChange = async (value) => {
    console.log("value", value);
    setSelectValue(value);
    await getBilling(
      startingDate || defaultStartDate,
      endingDate || defaultEndDate,
      value.toUpperCase()
    );
  };

  const onChangeDate = async (date, dateArray) => {
    console.log("coming", date);
    dispatch(setLoading(true));

    setRangePickerValue(date);

    if (!dateArray[0] && !dateArray[1]) {
      setDefaultStartDate(defaultStartDate);
      setDefaultEndDate(defaultEndDate);
      await getBilling(
        defaultStartDate,
        defaultEndDate,
        selectValue.toUpperCase()
      );
      dispatch(setLoading(false));
    }
    const startDate = new Date(date[0]?.$d);
    const endDate = new Date(date[1]?.$d);

    endDate.setHours(23, 59, 59, 999);

    setStartingDate(startDate);
    setEndingDate(endDate);
    await getBilling(startDate, endDate, selectValue.toUpperCase());
    dispatch(setLoading(false));
  };

  useEffect(() => {
    getBilling(defaultStartDate, defaultEndDate, selectValue.toUpperCase());
  }, []);

  const onTabChange = (key) => {
    dispatch(setIsMonthSelected(key == "month" ? "MONTHLY" : "DAILY"));
  };
  console.log("key", selectValue);

  return (
    <>
      <Header Pagename={"Billing Insights "} />
      {loading ? (
        <SkeletonLoader />
      ) : (
        <div
          className={`mt-3 `}
          style={{ margin: `0 ${screenWidth > 1024 ? "125px" : "30px"}` }}
        >
          {screenWidth > 768 ? (
            <Row>
              <Col xs={screenWidth > 768 ? 8 : 12} className="px-3">
                <BillingTable
                  rangePresets={rangePresets}
                  defaultStartDate={defaultStartDate}
                  defaultEndDate={defaultEndDate}
                  rangePickerValue={rangePickerValue}
                  onChangeDate={onChangeDate}
                  onTabChange={onTabChange}
                  handleChange={handleChange}
                  selectValue={selectValue}
                  screenWidth={screenWidth}
                />
              </Col>
              <Col className="lg:w-1/3 md:w-full px-1">
                {/* <Card>
                  <Card1Content billingData={billingData} />
                </Card> */}
                <Card>
                  <Card2Content billingData={billingData} />
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className="lg:w-1/3 md:w-full px-1">
                {/* <Card>
                  <Card1Content billingData={billingData} />
                </Card> */}
                <Card>
                  <Card2Content billingData={billingData} />
                </Card>
              </Col>
              <Col xs={screenWidth > 768 ? 8 : 12} className="px-1">
                <BillingTable
                  rangePresets={rangePresets}
                  defaultStartDate={defaultStartDate}
                  defaultEndDate={defaultEndDate}
                  rangePickerValue={rangePickerValue}
                  onChangeDate={onChangeDate}
                  onTabChange={onTabChange}
                  handleChange={handleChange}
                  selectValue={selectValue}
                  screenWidth={screenWidth}
                />
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  );
};

export default BillingInsights;
