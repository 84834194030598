import React from "react";
import "./styles.css";
const GroupNode = ({
  id,
  draggable,
  onDragStart,
  title,
  description,
  icon,
  colour,
}) => {
  return (
    <div
      className="send-message"
      draggable={draggable}
      onDragStart={onDragStart}
    >
      <div className="left-line" style={{ background: colour }}></div>
      <div className="content">
        <h3 style={{ color: colour }}>{title}</h3>
        <p>{description}</p>
      </div>
      <div className="icon">
        <img src={icon} alt="" />
      </div>
    </div>
  );
};

export default GroupNode;
