import React, { useEffect, useState, useRef } from "react";
import "./styles.css";
import { Position } from "@xyflow/react";

import { useSelector, useDispatch } from "react-redux";
import ToggleOn from "../../../../images/toggle-on.svg";
import ToggleOff from "../../../../images/toggle-off.svg";
import CustomSelect from "../Template/CustomSelect";
import CustomHandle from "../Ask-a-question/CustomHandle";

const Form = ({ id, data }) => {
  const { nodes } = useSelector((state) => state.WorkflowReducer);
  const SELECT_OPTIONS = [
    // { value: "formTitle", label: "Form Title" },
    { value: "text", label: "Text" },
    { value: "email", label: "Email" },
    { value: "number", label: "Phone" },
    { value: "date", label: "Date Time" },
    { value: "select", label: "Select" },
    { value: "radio", label: "Radio Buttons" },
  ];
  const [isSaved, setIsSaved] = useState(false);
  const [fields, setFields] = useState([]);
  const componentRef = useRef(null);
  const [isHover, setIsHover] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);

  // State to store the height
  const [componentHeight, setComponentHeight] = useState(0);
  const [selectValue, setSelectValue] = useState("");
  const [fieldSelectValue, setFieldSelectValue] = useState("");

  // Measure the component height when `isSaved` is false
  useEffect(() => {
    if (!isSaved && componentRef.current) {
      const height = componentRef.current.getBoundingClientRect().height;
      console.log("height", height);
      setComponentHeight(height);
    }
  }, [isSaved]);

  const handleToggle = (index) => {
    const updatedFields = fields.map((field, idx) => {
      if (idx === index) {
        return { ...field, isRequired: !field.isRequired }; // Toggle the 'required' property
      }
      return field;
    });
    setFields(updatedFields); // Update state with the toggled field
  };
  const onLabelChange = (e, index) => {
    const updatedFields = fields.map((field, idx) => {
      if (idx === index) {
        return { ...field, label: e.target.value }; // Toggle the 'required' property
      }
      return field;
    });
    setFields(updatedFields);
  };

  const onNameChange = (e, index) => {
    const updatedFields = fields.map((field, idx) => {
      if (idx === index) {
        return { ...field, name: e.target.value }; // Toggle the 'required' property
      }
      return field;
    });
    setFields(updatedFields);
  };

  const onFieldChange = (e, index) => {
    const updatedFields = fields.map((field, idx) => {
      if (idx === index) {
        return { ...field, field: e.target.value }; // Toggle the 'required' property
      }
      return field;
    });
    setFields(updatedFields);
  };

  const handleCancelClick = () => {
    setFields([]);
  };
  const getUniqueId = () => Math.random().toString(36).substr(2, 9);

  const handleSelectChange = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    setSelectValue(selectedOption);
    if (selectedOption) {
      setFields([
        ...fields,
        {
          id: getUniqueId,
          label: selectedOption?.value === "select" ? "Select" : "",
          isRequired: false,
          field: "",
          type: selectedOption,
          ...(selectedOption?.value === "select" && {
            options: [{ value: "option1", label: "Option 1" }],
          }),
          ...(selectedOption?.value === "radio" && {
            radioOptions: [{ value: "option1", label: "Option 1" }],
          }),
          fieldType:
            selectedOption?.value == "radio"
              ? "RadioButton"
              : selectedOption?.value === "select"
              ? "SelectBox"
              : "Input",
        },
      ]);
    }
  };
  const handleFieldSelectChange = (selectedOption) => {
    setFieldSelectValue(selectedOption);
  };

  const handleSaveClick = () => {
    if (!componentRef.current) return;

    // Measure the final height of the form node
    // Measure the final height of the form node
    const currentHeight = componentRef.current.getBoundingClientRect().height;
    const submitButtonHeight = 50; // Height of the Submit button (adjust if needed)
    const padding = 20; // Optional padding for spacing
    const finalHeight = currentHeight + submitButtonHeight + padding;
    console.log("finalHeight", finalHeight);

    data.updateNodeData(id, {
      fields,
      style: {
        ...data.style, // Preserve existing styles
        height: `${finalHeight}px`, // Dynamically adjust height
      },
    });
    const child = {
      id: getUniqueId(),
      type: "submitForm",
      position: {
        x: 10,
        y: finalHeight,
      },
      data: { id: getUniqueId(), value: "Submit" },
      parentId: id,
      extent: "parent",
      draggable: false,
      style: {
        minWidth: "260px",
        maxHeight: `39px`,
        padding: "0",
        background: "transparent",
        zIndex: "110",
      },
    };
    data.addSingleChildToNode(child);

    setIsSaved(true);
  };

  const handleDeleteField = (id) => {
    console.log("remainingFields", id);

    const remainingFields = fields?.filter((field) => field?.id != id);
    console.log("remainingFields", remainingFields);
    setFields(remainingFields);
  };

  const handleAddField = (fieldIndex, optionIdx) => {
    const updatedFields = [...fields];
    updatedFields[fieldIndex].options.push({
      value: `option${optionIdx + 1}`,
      label: `Option ${optionIdx + 1}`,
    });
    setFields(updatedFields);
  };

  const handleDeleteSelectOptionField = (fieldIndex, optionIdx, option) => {
    const updatedFields = [...fields];
    updatedFields[fieldIndex].options = updatedFields[
      fieldIndex
    ].options.filter(
      (opt) =>
        updatedFields[fieldIndex].options.length > 0 &&
        opt.value !== option.value
    );

    setFields(updatedFields);
  };

  const handleRadioAddField = (fieldIndex, optionIdx) => {
    const updatedFields = [...fields];
    if (updatedFields[fieldIndex].radioOptions?.length < 3) {
      updatedFields[fieldIndex].radioOptions.push({
        value: `option${optionIdx + 1}`,
        label: `Option ${optionIdx + 1}`,
      });
    }

    setFields(updatedFields);
  };

  const handleRadioDeleteField = (fieldIndex, optionIdx, option) => {
    const updatedFields = [...fields];
    updatedFields[fieldIndex].radioOptions = updatedFields[
      fieldIndex
    ].radioOptions.filter(
      (opt) =>
        updatedFields[fieldIndex].radioOption?.length > 0 &&
        opt.value !== option.value
    );

    setFields(updatedFields);
  };

  // Handle input change for options
  const handleInputChange = (fieldIndex, optionIndex, value) => {
    setFields((prevFields) => {
      const updatedFields = [...prevFields];
      const updatedOptions = [...updatedFields[fieldIndex].options];

      updatedOptions[optionIndex] = { value, label: value.toUpperCase() };
      updatedFields[fieldIndex] = {
        ...updatedFields[fieldIndex],
        options: updatedOptions,
      };

      return updatedFields;
    });
  };

  const handleRadioInputChange = (fieldIndex, optionIndex, value) => {
    setFields((prevFields) => {
      const updatedFields = [...prevFields];
      const updatedOptions = [...updatedFields[fieldIndex].radioOptions];

      updatedOptions[optionIndex] = { value, label: value.toUpperCase() };
      updatedFields[fieldIndex] = {
        ...updatedFields[fieldIndex],
        radioOptions: updatedOptions,
      };

      return updatedFields;
    });
  };

  const onRadioChange = (e, idx) => {
    const { value } = e.target;

    setFields((prevFields) =>
      prevFields.map((field, i) =>
        i === idx ? { ...field, field: value } : field
      )
    );
  };

  const handleEditClick = () => {
    setPopupOpen(false);

    data?.deleteNode(id);

    setIsSaved(false);
  };

  const renderTypeWithDeleteButton = (field, idx) => {
    return (
      <>
        {" "}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <p className="field-type">Type: {field?.type?.value}</p>
          <div
            style={{
              cursor: "pointer",
              width: "32px",
              height: "32px",
              backgroundColor: "white",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onMouseEnter={() => setIsHover(idx)}
            onMouseLeave={() => setIsHover(null)}
            onClick={() => handleDeleteField(field?.id)}
          >
            {isHover != idx ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.69801 13.1596V6.99182C6.69801 6.65105 6.95545 6.37505 7.2733 6.37505C7.59116 6.37505 7.84859 6.65105 7.84859 6.99182V13.1596C7.84859 13.5004 7.59116 13.7764 7.2733 13.7764C6.95545 13.7764 6.69801 13.5004 6.69801 13.1596ZM10.725 13.7764C10.8775 13.7764 11.0242 13.7116 11.132 13.596C11.2399 13.4803 11.3003 13.323 11.3003 13.1596V6.99183C11.3003 6.65105 11.0429 6.37505 10.725 6.37505C10.4072 6.37505 10.1497 6.65105 10.1497 6.99183V13.1596C10.1497 13.323 10.2101 13.4803 10.318 13.596C10.4259 13.7116 10.5726 13.7764 10.725 13.7764ZM15.9026 4.04116C15.9026 4.20461 15.8422 4.36189 15.7344 4.47753C15.6265 4.59317 15.4798 4.65793 15.3274 4.65793H14.1768V14.8181C14.1904 15.8558 13.4181 16.7092 12.4509 16.727H5.54744C4.58024 16.7093 3.80791 15.8558 3.82157 14.8181V4.65793H2.67099C2.35314 4.65793 2.0957 4.38193 2.0957 4.04116C2.0957 3.70038 2.35314 3.42438 2.67099 3.42438H5.67336L5.92218 2.35891C6.11419 1.53551 6.80453 0.957275 7.597 0.957275H10.4022C11.1947 0.957275 11.8843 1.53474 12.0763 2.35891L12.3251 3.42438H15.3275C15.4799 3.42438 15.6266 3.48914 15.7345 3.60479C15.8424 3.72043 15.9026 3.87772 15.9026 4.04116ZM6.85983 3.42439H11.1385L10.9602 2.65882C10.8969 2.38359 10.666 2.19084 10.4014 2.19084H7.59694C7.33231 2.19084 7.1022 2.38358 7.03819 2.65805L6.85983 3.42439ZM13.0262 4.65794H4.97214V14.8181C4.95848 15.1743 5.2152 15.4765 5.54743 15.4934H12.4509C12.7831 15.4765 13.0399 15.1743 13.0262 14.8181V4.65794Z"
                  fill="#4B4A4A"
                />
              </svg>
            ) : (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.69801 13.1596V6.99182C6.69801 6.65105 6.95545 6.37505 7.2733 6.37505C7.59116 6.37505 7.84859 6.65105 7.84859 6.99182V13.1596C7.84859 13.5004 7.59116 13.7764 7.2733 13.7764C6.95545 13.7764 6.69801 13.5004 6.69801 13.1596ZM10.725 13.7764C10.8775 13.7764 11.0242 13.7116 11.132 13.596C11.2399 13.4803 11.3003 13.323 11.3003 13.1596V6.99183C11.3003 6.65105 11.0429 6.37505 10.725 6.37505C10.4072 6.37505 10.1497 6.65105 10.1497 6.99183V13.1596C10.1497 13.323 10.2101 13.4803 10.318 13.596C10.4259 13.7116 10.5726 13.7764 10.725 13.7764ZM15.9026 4.04116C15.9026 4.20461 15.8422 4.36189 15.7344 4.47753C15.6265 4.59317 15.4798 4.65793 15.3274 4.65793H14.1768V14.8181C14.1904 15.8558 13.4181 16.7092 12.4509 16.727H5.54744C4.58024 16.7093 3.80791 15.8558 3.82157 14.8181V4.65793H2.67099C2.35314 4.65793 2.0957 4.38193 2.0957 4.04116C2.0957 3.70038 2.35314 3.42438 2.67099 3.42438H5.67336L5.92218 2.35891C6.11419 1.53551 6.80453 0.957275 7.597 0.957275H10.4022C11.1947 0.957275 11.8843 1.53474 12.0763 2.35891L12.3251 3.42438H15.3275C15.4799 3.42438 15.6266 3.48914 15.7345 3.60479C15.8424 3.72043 15.9026 3.87772 15.9026 4.04116ZM6.85983 3.42439H11.1385L10.9602 2.65882C10.8969 2.38359 10.666 2.19084 10.4014 2.19084H7.59694C7.33231 2.19084 7.1022 2.38358 7.03819 2.65805L6.85983 3.42439ZM13.0262 4.65794H4.97214V14.8181C4.95848 15.1743 5.2152 15.4765 5.54743 15.4934H12.4509C12.7831 15.4765 13.0399 15.1743 13.0262 14.8181V4.65794Z"
                  fill="#F02D2D"
                />
              </svg>
            )}
          </div>
        </div>
        <hr style={{ marginTop: "10px", marginBottom: "20px" }} />
      </>
    );
  };

  const renderDeleteButtonWithoutType = (field, idx) => {
    return (
      <div
        style={{
          cursor: "pointer",
          width: "32px",
          height: "32px",
          // backgroundColor: "white",
          borderRadius: "4px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
        }}
        onMouseEnter={() => setIsHover(idx)}
        onMouseLeave={() => setIsHover(null)}
        onClick={() => handleDeleteField(field?.id)}
      >
        {isHover != idx ? (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.69801 13.1596V6.99182C6.69801 6.65105 6.95545 6.37505 7.2733 6.37505C7.59116 6.37505 7.84859 6.65105 7.84859 6.99182V13.1596C7.84859 13.5004 7.59116 13.7764 7.2733 13.7764C6.95545 13.7764 6.69801 13.5004 6.69801 13.1596ZM10.725 13.7764C10.8775 13.7764 11.0242 13.7116 11.132 13.596C11.2399 13.4803 11.3003 13.323 11.3003 13.1596V6.99183C11.3003 6.65105 11.0429 6.37505 10.725 6.37505C10.4072 6.37505 10.1497 6.65105 10.1497 6.99183V13.1596C10.1497 13.323 10.2101 13.4803 10.318 13.596C10.4259 13.7116 10.5726 13.7764 10.725 13.7764ZM15.9026 4.04116C15.9026 4.20461 15.8422 4.36189 15.7344 4.47753C15.6265 4.59317 15.4798 4.65793 15.3274 4.65793H14.1768V14.8181C14.1904 15.8558 13.4181 16.7092 12.4509 16.727H5.54744C4.58024 16.7093 3.80791 15.8558 3.82157 14.8181V4.65793H2.67099C2.35314 4.65793 2.0957 4.38193 2.0957 4.04116C2.0957 3.70038 2.35314 3.42438 2.67099 3.42438H5.67336L5.92218 2.35891C6.11419 1.53551 6.80453 0.957275 7.597 0.957275H10.4022C11.1947 0.957275 11.8843 1.53474 12.0763 2.35891L12.3251 3.42438H15.3275C15.4799 3.42438 15.6266 3.48914 15.7345 3.60479C15.8424 3.72043 15.9026 3.87772 15.9026 4.04116ZM6.85983 3.42439H11.1385L10.9602 2.65882C10.8969 2.38359 10.666 2.19084 10.4014 2.19084H7.59694C7.33231 2.19084 7.1022 2.38358 7.03819 2.65805L6.85983 3.42439ZM13.0262 4.65794H4.97214V14.8181C4.95848 15.1743 5.2152 15.4765 5.54743 15.4934H12.4509C12.7831 15.4765 13.0399 15.1743 13.0262 14.8181V4.65794Z"
              fill="#4B4A4A"
            />
          </svg>
        ) : (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.69801 13.1596V6.99182C6.69801 6.65105 6.95545 6.37505 7.2733 6.37505C7.59116 6.37505 7.84859 6.65105 7.84859 6.99182V13.1596C7.84859 13.5004 7.59116 13.7764 7.2733 13.7764C6.95545 13.7764 6.69801 13.5004 6.69801 13.1596ZM10.725 13.7764C10.8775 13.7764 11.0242 13.7116 11.132 13.596C11.2399 13.4803 11.3003 13.323 11.3003 13.1596V6.99183C11.3003 6.65105 11.0429 6.37505 10.725 6.37505C10.4072 6.37505 10.1497 6.65105 10.1497 6.99183V13.1596C10.1497 13.323 10.2101 13.4803 10.318 13.596C10.4259 13.7116 10.5726 13.7764 10.725 13.7764ZM15.9026 4.04116C15.9026 4.20461 15.8422 4.36189 15.7344 4.47753C15.6265 4.59317 15.4798 4.65793 15.3274 4.65793H14.1768V14.8181C14.1904 15.8558 13.4181 16.7092 12.4509 16.727H5.54744C4.58024 16.7093 3.80791 15.8558 3.82157 14.8181V4.65793H2.67099C2.35314 4.65793 2.0957 4.38193 2.0957 4.04116C2.0957 3.70038 2.35314 3.42438 2.67099 3.42438H5.67336L5.92218 2.35891C6.11419 1.53551 6.80453 0.957275 7.597 0.957275H10.4022C11.1947 0.957275 11.8843 1.53474 12.0763 2.35891L12.3251 3.42438H15.3275C15.4799 3.42438 15.6266 3.48914 15.7345 3.60479C15.8424 3.72043 15.9026 3.87772 15.9026 4.04116ZM6.85983 3.42439H11.1385L10.9602 2.65882C10.8969 2.38359 10.666 2.19084 10.4014 2.19084H7.59694C7.33231 2.19084 7.1022 2.38358 7.03819 2.65805L6.85983 3.42439ZM13.0262 4.65794H4.97214V14.8181C4.95848 15.1743 5.2152 15.4765 5.54743 15.4934H12.4509C12.7831 15.4765 13.0399 15.1743 13.0262 14.8181V4.65794Z"
              fill="#F02D2D"
            />
          </svg>
        )}
      </div>
    );
  };

  const renderSelectBeforeSave = (optionIdx, idx, option, optionLength) => {
    return (
      <div key={optionIdx} className="main-option-container">
        <label className="label">Option {optionIdx + 1}</label>
        <div className="option-field-container">
          <input
            type="text"
            className="option-field"
            placeholder={`Option ${optionIdx + 1}`}
            value={option.value}
            onChange={(e) => handleInputChange(idx, optionIdx, e.target.value)}
          />
          {optionIdx == optionLength - 1 ? (
            <button
              className="add-option-btn"
              onClick={() => handleAddField(idx, optionIdx + 1)}
            >
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: "23px", height: "23px" }}
              >
                <path
                  d="M10.5415 12.4584H5.74984C5.47831 12.4584 5.25071 12.3666 5.06702 12.1829C4.88334 11.9992 4.7915 11.7716 4.7915 11.5001C4.7915 11.2286 4.88334 11.0009 5.06702 10.8173C5.25071 10.6336 5.47831 10.5417 5.74984 10.5417H10.5415V5.75008C10.5415 5.47855 10.6333 5.25095 10.817 5.06727C11.0007 4.88359 11.2283 4.79175 11.4998 4.79175C11.7714 4.79175 11.999 4.88359 12.1827 5.06727C12.3663 5.25095 12.4582 5.47855 12.4582 5.75008V10.5417H17.2498C17.5214 10.5417 17.749 10.6336 17.9327 10.8173C18.1163 11.0009 18.2082 11.2286 18.2082 11.5001C18.2082 11.7716 18.1163 11.9992 17.9327 12.1829C17.749 12.3666 17.5214 12.4584 17.2498 12.4584H12.4582V17.2501C12.4582 17.5216 12.3663 17.7492 12.1827 17.9329C11.999 18.1166 11.7714 18.2084 11.4998 18.2084C11.2283 18.2084 11.0007 18.1166 10.817 17.9329C10.6333 17.7492 10.5415 17.5216 10.5415 17.2501V12.4584Z"
                  fill="white"
                />
              </svg>
            </button>
          ) : (
            <button
              className="add-option-btn"
              onClick={() =>
                handleDeleteSelectOptionField(idx, optionIdx + 1, option)
              }
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.125 18.375C5.64375 18.375 5.23177 18.2036 4.88906 17.8609C4.54635 17.5182 4.375 17.1062 4.375 16.625V5.25C4.12708 5.25 3.91927 5.16615 3.75156 4.99844C3.58385 4.83073 3.5 4.62292 3.5 4.375C3.5 4.12708 3.58385 3.91927 3.75156 3.75156C3.91927 3.58385 4.12708 3.5 4.375 3.5H7.875C7.875 3.25208 7.95885 3.04427 8.12656 2.87656C8.29427 2.70885 8.50208 2.625 8.75 2.625H12.25C12.4979 2.625 12.7057 2.70885 12.8734 2.87656C13.0411 3.04427 13.125 3.25208 13.125 3.5H16.625C16.8729 3.5 17.0807 3.58385 17.2484 3.75156C17.4161 3.91927 17.5 4.12708 17.5 4.375C17.5 4.62292 17.4161 4.83073 17.2484 4.99844C17.0807 5.16615 16.8729 5.25 16.625 5.25V16.625C16.625 17.1062 16.4536 17.5182 16.1109 17.8609C15.7682 18.2036 15.3562 18.375 14.875 18.375H6.125ZM14.875 5.25H6.125V16.625H14.875V5.25ZM8.75 14.875C8.99792 14.875 9.20573 14.7911 9.37344 14.6234C9.54115 14.4557 9.625 14.2479 9.625 14V7.875C9.625 7.62708 9.54115 7.41927 9.37344 7.25156C9.20573 7.08385 8.99792 7 8.75 7C8.50208 7 8.29427 7.08385 8.12656 7.25156C7.95885 7.41927 7.875 7.62708 7.875 7.875V14C7.875 14.2479 7.95885 14.4557 8.12656 14.6234C8.29427 14.7911 8.50208 14.875 8.75 14.875ZM12.25 14.875C12.4979 14.875 12.7057 14.7911 12.8734 14.6234C13.0411 14.4557 13.125 14.2479 13.125 14V7.875C13.125 7.62708 13.0411 7.41927 12.8734 7.25156C12.7057 7.08385 12.4979 7 12.25 7C12.0021 7 11.7943 7.08385 11.6266 7.25156C11.4589 7.41927 11.375 7.62708 11.375 7.875V14C11.375 14.2479 11.4589 14.4557 11.6266 14.6234C11.7943 14.7911 12.0021 14.875 12.25 14.875Z"
                  fill="white"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderRadioBeforeSave = (optionIdx, idx, option, optionsLength) => {
    return (
      <div key={optionIdx} className="main-option-container">
        <label className="label">Option {optionIdx + 1}</label>
        <div className="option-field-container">
          <input
            type="text"
            className="option-field"
            placeholder={`Option ${optionIdx + 1}`}
            value={option.value}
            onChange={(e) =>
              handleRadioInputChange(idx, optionIdx, e.target.value)
            }
          />
          {optionIdx == optionsLength - 1 ? (
            <button
              className="add-option-btn"
              onClick={() => handleRadioAddField(idx, optionIdx + 1)}
            >
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: "23px", height: "23px" }}
              >
                <path
                  d="M10.5415 12.4584H5.74984C5.47831 12.4584 5.25071 12.3666 5.06702 12.1829C4.88334 11.9992 4.7915 11.7716 4.7915 11.5001C4.7915 11.2286 4.88334 11.0009 5.06702 10.8173C5.25071 10.6336 5.47831 10.5417 5.74984 10.5417H10.5415V5.75008C10.5415 5.47855 10.6333 5.25095 10.817 5.06727C11.0007 4.88359 11.2283 4.79175 11.4998 4.79175C11.7714 4.79175 11.999 4.88359 12.1827 5.06727C12.3663 5.25095 12.4582 5.47855 12.4582 5.75008V10.5417H17.2498C17.5214 10.5417 17.749 10.6336 17.9327 10.8173C18.1163 11.0009 18.2082 11.2286 18.2082 11.5001C18.2082 11.7716 18.1163 11.9992 17.9327 12.1829C17.749 12.3666 17.5214 12.4584 17.2498 12.4584H12.4582V17.2501C12.4582 17.5216 12.3663 17.7492 12.1827 17.9329C11.999 18.1166 11.7714 18.2084 11.4998 18.2084C11.2283 18.2084 11.0007 18.1166 10.817 17.9329C10.6333 17.7492 10.5415 17.5216 10.5415 17.2501V12.4584Z"
                  fill="white"
                />
              </svg>
            </button>
          ) : (
            <button
              className="add-option-btn"
              onClick={() => handleRadioDeleteField(idx, optionIdx + 1, option)}
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.125 18.375C5.64375 18.375 5.23177 18.2036 4.88906 17.8609C4.54635 17.5182 4.375 17.1062 4.375 16.625V5.25C4.12708 5.25 3.91927 5.16615 3.75156 4.99844C3.58385 4.83073 3.5 4.62292 3.5 4.375C3.5 4.12708 3.58385 3.91927 3.75156 3.75156C3.91927 3.58385 4.12708 3.5 4.375 3.5H7.875C7.875 3.25208 7.95885 3.04427 8.12656 2.87656C8.29427 2.70885 8.50208 2.625 8.75 2.625H12.25C12.4979 2.625 12.7057 2.70885 12.8734 2.87656C13.0411 3.04427 13.125 3.25208 13.125 3.5H16.625C16.8729 3.5 17.0807 3.58385 17.2484 3.75156C17.4161 3.91927 17.5 4.12708 17.5 4.375C17.5 4.62292 17.4161 4.83073 17.2484 4.99844C17.0807 5.16615 16.8729 5.25 16.625 5.25V16.625C16.625 17.1062 16.4536 17.5182 16.1109 17.8609C15.7682 18.2036 15.3562 18.375 14.875 18.375H6.125ZM14.875 5.25H6.125V16.625H14.875V5.25ZM8.75 14.875C8.99792 14.875 9.20573 14.7911 9.37344 14.6234C9.54115 14.4557 9.625 14.2479 9.625 14V7.875C9.625 7.62708 9.54115 7.41927 9.37344 7.25156C9.20573 7.08385 8.99792 7 8.75 7C8.50208 7 8.29427 7.08385 8.12656 7.25156C7.95885 7.41927 7.875 7.62708 7.875 7.875V14C7.875 14.2479 7.95885 14.4557 8.12656 14.6234C8.29427 14.7911 8.50208 14.875 8.75 14.875ZM12.25 14.875C12.4979 14.875 12.7057 14.7911 12.8734 14.6234C13.0411 14.4557 13.125 14.2479 13.125 14V7.875C13.125 7.62708 13.0411 7.41927 12.8734 7.25156C12.7057 7.08385 12.4979 7 12.25 7C12.0021 7 11.7943 7.08385 11.6266 7.25156C11.4589 7.41927 11.375 7.62708 11.375 7.875V14C11.375 14.2479 11.4589 14.4557 11.6266 14.6234C11.7943 14.7911 12.0021 14.875 12.25 14.875Z"
                  fill="white"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="form" ref={componentRef}>
        <div className="header">
          <h3>Form</h3>
          {popupOpen && (
            <div className="popup">
              <p onClick={handleEditClick}>Edit</p>
            </div>
          )}
          <div onClick={() => setPopupOpen(!popupOpen)}>
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="me-3 cursor-pointer"
            >
              <path
                d="M13.5 9.5C12.8125 9.5 12.224 9.27969 11.7344 8.83906C11.2448 8.39844 11 7.86875 11 7.25C11 6.63125 11.2448 6.10156 11.7344 5.66094C12.224 5.22031 12.8125 5 13.5 5C14.1875 5 14.776 5.22031 15.2656 5.66094C15.7552 6.10156 16 6.63125 16 7.25C16 7.86875 15.7552 8.39844 15.2656 8.83906C14.776 9.27969 14.1875 9.5 13.5 9.5ZM13.5 16.25C12.8125 16.25 12.224 16.0297 11.7344 15.5891C11.2448 15.1484 11 14.6187 11 14C11 13.3813 11.2448 12.8516 11.7344 12.4109C12.224 11.9703 12.8125 11.75 13.5 11.75C14.1875 11.75 14.776 11.9703 15.2656 12.4109C15.7552 12.8516 16 13.3813 16 14C16 14.6187 15.7552 15.1484 15.2656 15.5891C14.776 16.0297 14.1875 16.25 13.5 16.25ZM13.5 23C12.8125 23 12.224 22.7797 11.7344 22.3391C11.2448 21.8984 11 21.3687 11 20.75C11 20.1313 11.2448 19.6016 11.7344 19.1609C12.224 18.7203 12.8125 18.5 13.5 18.5C14.1875 18.5 14.776 18.7203 15.2656 19.1609C15.7552 19.6016 16 20.1313 16 20.75C16 21.3687 15.7552 21.8984 15.2656 22.3391C14.776 22.7797 14.1875 23 13.5 23Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div className="body-content">
          <>
            {fields?.length > 0 &&
              fields?.map((field, idx) => {
                console.log("filed", field);
                const isSelect = field?.type?.value == "select";
                const isRadio = field?.type?.value == "radio";
                const isFormTitle = field?.type?.value == "formTitle";
                return (
                  <div className="field-container">
                    {!isSaved &&
                      !isFormTitle &&
                      renderTypeWithDeleteButton(field, idx)}
                    {
                      <div className="label-row">
                        {!isSaved ? (
                          <>
                            <input
                              type="text"
                              name=""
                              id=""
                              className="label "
                              placeholder="label"
                              onChange={(e) => onLabelChange(e, idx)}
                              value={field.label}
                            />
                            <input
                              type="text"
                              name=""
                              id=""
                              className="label pb-3 name-field"
                              placeholder="name"
                              onChange={(e) => onNameChange(e, idx)}
                              value={field.name}
                            />
                            {isFormTitle &&
                              renderDeleteButtonWithoutType(field, idx)}
                          </>
                        ) : (
                          <p className="label">{field.label}</p>
                        )}
                      </div>
                    }
                    {isFormTitle && (
                      <textarea
                        type="text"
                        name=""
                        id=""
                        className="field"
                        onChange={(e) => onFieldChange(e, idx)}
                        value={field.field}
                        style={{ height: "70px" }}
                      />
                    )}
                    {isSelect ? (
                      !isSaved ? (
                        field?.options?.map((option, optionIdx) => {
                          return renderSelectBeforeSave(
                            optionIdx,
                            idx,
                            option,
                            field?.options?.length
                          );
                        })
                      ) : (
                        <CustomSelect
                          placeholder="Select"
                          options={field?.options}
                          onChange={handleFieldSelectChange}
                          value={fieldSelectValue}
                        />
                      )
                    ) : isRadio ? (
                      !isSaved ? (
                        field?.radioOptions?.map((option, optionIdx) => {
                          return renderRadioBeforeSave(
                            optionIdx,
                            idx,
                            option,
                            field?.radioOptions.length
                          );
                        })
                      ) : (
                        <div className="radio-group">
                          {field?.radioOptions?.map((option, optionIdx) => (
                            <label key={optionIdx} className="radio-label">
                              <input
                                type="radio"
                                name={`radio-group-${idx}`} // Unique name for each radio group
                                defaultValue={option}
                                className="radio-btn"
                                // value={option.value}
                                // checked={field.field === option.value}
                                // onChange={(e) =>
                                //  onRadioChange()
                                // }
                              />
                              {option.label}
                            </label>
                          ))}
                        </div>
                      )
                    ) : (
                      !isFormTitle && (
                        <input
                          type={field?.type?.value}
                          name=""
                          id=""
                          className="field"
                          onChange={(e) => onFieldChange(e, idx)}
                          value={field.field}
                        />
                      )
                    )}

                    {!isSaved && !isFormTitle && (
                      <p className="required">
                        Required
                        <img
                          src={field.isRequired ? ToggleOn : ToggleOff} // Use a different icon for on/off states
                          className="toggle"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleToggle(idx)}
                        />
                      </p>
                    )}
                  </div>
                );
              })}
            {!isSaved && (
              <>
                {" "}
                <div className="mt-2"></div>
                <CustomSelect
                  placeholder="Select Fields"
                  options={SELECT_OPTIONS}
                  onChange={handleSelectChange}
                  value={selectValue}
                />
              </>
            )}

            {!isSaved && (
              <div className="save-btn-container">
                <button className="btn-cancel" onClick={handleCancelClick}>
                  Cancel
                </button>
                <button className="btn-save" onClick={handleSaveClick}>
                  Save
                </button>
              </div>
            )}
            <div style={{ marginTop: "50px" }}></div>
          </>
        </div>
      </div>
      <CustomHandle type="target" position={Position.Left} />
    </>
  );
};

export default Form;
