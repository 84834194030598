import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Flex, Progress } from "antd";
import useDeviceWidthChecker from "../customHooks/DeviceWidthDetector";

const Card1Content = () => {
  const screenWidth = useDeviceWidthChecker();

  return (
    <div className="card1-content">
      <h3 className="heading">Messages Consumption</h3>
      <Row className="mt-4">
        <Col md={6}>
          <Flex
            gap="small"
            wrap
            style={
              screenWidth <= 430
                ? {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                : {}
            }
          >
            <Progress
              type="circle"
              percent={55}
              strokeColor="#F7AA4E"
              trailColor="#FDEEDC"
              size={screenWidth > 430 && screenWidth <= 768 ? 160 : 130}
              strokeWidth={10}
              showInfo={true}
              format={() => (
                <>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#737373",
                      fontWeight: "500",
                    }}
                  >
                    Consumed
                  </p>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    10301000
                  </p>
                </>
              )}
            />
          </Flex>
        </Col>
        <Col md={6} className={screenWidth > 430 ? "mt-2" : "mt-4"}>
          <Row>
            <Col md={12} xs={6} className="mb-3">
              <h4 className="message-heading">Available Messages</h4>
              <p className="messages">12000</p>
            </Col>
            <Col md={12} xs={6}>
              <h4 className="message-heading">Consumed Messages</h4>
              <p className="messages">
                <span className="consumed-msgs">3100</span> / 43000
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Card1Content;
