import React, { useState, useEffect } from "react";
import { DatePicker, Space } from "antd";
import "./styles.css";
import { Table, Tabs, Select } from "antd";
import useDeviceWidthChecker from "../customHooks/DeviceWidthDetector";

import { useSelector } from "react-redux";
import {
  formatCostByCountry,
  formatTimestampToDate,
  formatTimestampToDateMonthly,
} from "../../utils/billingUtils";
import ResponsiveBillingTable from "./ResponsiveBillingTable";

const { RangePicker } = DatePicker;
const options = [
  {
    value: "daily",
    label: "Daily",
  },
  {
    value: "monthly",
    label: "Monthly",
  },
];
const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    align: "left",
    // width: 200,
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    align: "center",

    // width: 200,
  },
  {
    title: "Total Messages",
    dataIndex: "totalMessages",
    key: "totalMessages",
    align: "center",
    // width: 240,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    align: "center",
    // width: 100,
  },
];

const customSvgIcon = (
  <svg
    width="9"
    height="5"
    viewBox="0 0 9 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.95058 4.76064L0.156977 0.904255C0.104651 0.851064 0.065407 0.79344 0.0392442 0.731383C0.0130814 0.669326 0 0.602837 0 0.531915C0 0.390071 0.0479651 0.265957 0.143895 0.159574C0.239826 0.0531915 0.366279 0 0.523256 0H8.47674C8.63372 0 8.76017 0.0531915 8.8561 0.159574C8.95203 0.265957 9 0.390071 9 0.531915C9 0.567376 8.94767 0.691489 8.84302 0.904255L5.04942 4.76064C4.96221 4.84929 4.875 4.91135 4.78779 4.94681C4.70058 4.98227 4.60465 5 4.5 5C4.39535 5 4.29942 4.98227 4.21221 4.94681C4.125 4.91135 4.03779 4.84929 3.95058 4.76064Z"
      fill="#5F6368"
    />
  </svg>
);

const BillingTable = ({
  rangePresets,
  defaultStartDate,
  defaultEndDate,
  rangePickerValue,
  onChangeDate,
  onTabChange,
  handleChange,
  selectValue,
}) => {
  const { billingData, currency } = useSelector(
    (state) => state.BillingReducer
  );
  const [billingDetail, setBillingDetail] = useState([]);
  const screenWidth = useDeviceWidthChecker();

  console.log("billingDetail", billingData);

  const items = [
    {
      key: "day",
      label: "Days",
    },
    {
      key: "month",
      label: "Months",
    },
  ];

  useEffect(() => {
    // if (billingData?.length) {
    const mappedData = billingData?.map((data, idx) => ({
      key: idx + 1,
      date:
        selectValue == "daily"
          ? formatTimestampToDate(data?.start)
          : formatTimestampToDateMonthly(data?.start),
      category: data?.conversation_category,
      totalMessages: data?.conversation,
      // amount: formatCostByCountry(data.country, data.cost),
      amount: formatCostByCountry(currency?.currency, data.cost) || "$",
    }));
    setBillingDetail(mappedData);
    // }
  }, [billingData]);

  const isMonth = () => {
    return <Tabs defaultActiveKey="1" items={items} onChange={onTabChange} />;
  };
  return (
    <div className="billing-table-container">
      <div className="header">
        <h3 className="heading">Billing Summary</h3>
        <div
          style={{
            display: "flex",
            gap: "8px",
            flexDirection: screenWidth > 767 ? "row" : "column",
            width: "100%",
            justifyContent: "end",
          }}
        >
          {/* <Select
            defaultValue="daily"
            style={{
              width: screenWidth > 767 ? 120 : "100%",
              height: 40,
              // position: "relative",
              // top: "-2px",
            }}
            onChange={handleChange}
            options={options}
            suffixIcon={customSvgIcon}
            value={selectValue}
          />{" "} */}
          <RangePicker
            dropdownClassName="createDateRangePicker"
            className="dateRange custom-range-picker"
            presets={rangePresets}
            defaultValue={[defaultStartDate, defaultEndDate]}
            value={rangePickerValue}
            format={"YYYY-MM-DD"}
            onChange={onChangeDate}
            placeholder={["Start date", "End date"]}
            // renderExtraFooter={() => isMonth()}
          />
        </div>
      </div>

      {screenWidth >= 768 ? (
        <div className="table">
          <Table
            dataSource={billingDetail}
            columns={columns}
            // pagination={false}
            bordered={false}
            //   showHeader={false}
            className="custom-ant-table"
          />
        </div>
      ) : (
        <ResponsiveBillingTable billingDetail={billingDetail} />
      )}
    </div>
  );
};

export default BillingTable;
