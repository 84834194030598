import React, { useState, ReactNode } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import parse from "html-react-parser";
import Spinner from "react-bootstrap/Spinner";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "../inputField/index.tsx";
import InfoIcon from "../../images/icons/infoIcon.svg";

import { Tooltip, Zoom } from "@mui/material";

import CustomButton from "../customButton/index.tsx";
import { containsHTMLTags } from "../../utils/utils.js";
import useDeviceWidthChecker from "../customHooks/DeviceWidthDetector";

interface modalProps {
  isOpen: Boolean;
  OKText?: string;
  cancelText?: string;
  modalTitle: string;
  isHideCrossSign?: boolean;
  isCreateNewTag?: boolean;
  isDeleteTag?: boolean;
  isEditTag?: boolean;
  contentText?: string;
  isViewTag?: boolean;
  tagTitle?: string;
  tagKey?: string;
  isSending?: Boolean;
  isTextBold?: Boolean;
  errMsg?: string;
  onChangeData?: (value: string, key: string) => void;
  onOkClick: () => void;
  onCancelClick: () => void;
  children?: () => ReactNode;
}

const headerStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  borderBottom: "1px solid #0000001A",
  paddingBottom: "18px",
};
const headerTitle = {
  fontSize: "20px",
  color: "#000000",
  fontWeight: 500,
  fontFamily: "SEGOE UI",
};
const createNewTagContentStyles = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  rowGap: "10px",
};
const createNewTagContentHeaderTitle = {
  fontSize: "14px",
  color: "#000000",
  fontWeight: 600,
  fontFamily: "SEGOE UI",
  lineHeight: "18px",
  display: "flex",

  // alignItems:"center",
  gap: "2px",
};
const errorTitle = {
  fontSize: "12px",
  color: "red",
  fontWeight: 600,
  fontFamily: "SEGOE UI",
  lineHeight: "18px",
};
const footerStyles = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
  columnGap: "10px",
  padding: "20px",
};
const customContentStyle = {
  fontSize: "16px",
  color: "#000000",
  fontWeight: 400,
  lineHeight: "25px",
  fontFamily: "SEGOE UI",
};

const CustomPopup: React.FC<modalProps> = (props) => {
  const [show, setShow] = useState(false);
  const handleClick = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  const width = useDeviceWidthChecker();
  const fullScreen = useMediaQuery("min-width:768px");
  const [searchText, setSearchText] = useState("");
  const handleClickOk = () => {
    if (props?.isSending) {
      return;
    }
    if (props?.onOkClick != undefined) {
      props?.onOkClick();
    }
  };

  const handleClose = () => {
    if (props?.onCancelClick != undefined) {
      props?.onCancelClick();
    }
  };

  const handleOnChangeTagSearch = (val: string, key: string) => {
    if (props?.onChangeData != undefined) {
      props?.onChangeData(val, key);
    }
  };

  console.log(`tag errr`, props?.errMsg?.includes("tagKey"));

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props?.isOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" sx={{ padding: "20px" }}>
          <Box sx={headerStyles}>
            <Typography variant="h4" sx={headerTitle}>
              {props?.modalTitle}
            </Typography>
            {!props?.isHideCrossSign ? (
              <IconButton sx={{ padding: 0 }} onClick={handleClose}>
                <CloseIcon sx={{ fontSize: "25px", color: "#000000" }} />
              </IconButton>
            ) : null}
          </Box>
        </DialogTitle>

        <DialogContent sx={{ paddingBottom: "0px" }}>
          <DialogContentText>
            {props?.isCreateNewTag || props?.isEditTag || props?.isViewTag ? (
              <>
                <Box sx={createNewTagContentStyles}>
                  <Typography variant="h4" sx={createNewTagContentHeaderTitle}>
                    Tag Name
                  </Typography>
                  <InputField
                    value={props?.tagTitle}
                    placeholder="Tag Name"
                    width={
                      width > 540 ? "400px" : width > 320 ? "250px" : "200px"
                    }
                    disabled={props?.isViewTag}
                    height="40px"
                    backgroundColor="#F3F7F7"
                    isTextBold={props?.isTextBold}
                    handleOnChange={(val: string) =>
                      handleOnChangeTagSearch(val, "title")
                    }
                  />
                  <Typography variant="h4" sx={errorTitle}>
                    {props?.tagTitle?.length > 60
                      ? "Tag Name can not be more than 60 characters"
                      : props?.errMsg?.includes("Name") && props?.errMsg}
                  </Typography>
                </Box>
                <Box sx={createNewTagContentStyles}>
                  <Typography variant="h4" sx={createNewTagContentHeaderTitle}>
                    Tag Key
                    <Tooltip
                      arrow
                      TransitionComponent={Zoom}
                      title={
                        "This key will be used as a identifier of tag e.g. (#Support , #Marketing)"
                      }
                      placement={"top"}
                      sx={{ cursor: "pointer" }}
                      open={show}
                      onMouseEnter={() => setShow(true)}
                      onMouseLeave={() => setShow(false)}
                    >
                      <img
                        src={InfoIcon}
                        style={{ cursor: "pointer", marginLeft: "3px" }}
                      />
                    </Tooltip>
                  </Typography>
                  <InputField
                    value={props?.tagKey}
                    placeholder="Tag Key"
                    width={
                      width > 540 ? "400px" : width > 320 ? "250px" : "200px"
                    }
                    disabled={props?.isViewTag}
                    height="40px"
                    backgroundColor="#F3F7F7"
                    isTextBold={props?.isTextBold}
                    handleOnChange={(val: string) =>
                      handleOnChangeTagSearch(val, "tagKey")
                    }
                    flag={true}
                  />
                  <Typography variant="h4" sx={errorTitle}>
                    {props?.tagKey?.length > 60
                      ? "Tag Key can not be more than 60 characters"
                      : props?.errMsg?.includes("key") && props?.errMsg}
                  </Typography>
                </Box>
              </>
            ) : null}
            {props?.isDeleteTag ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                  paddingTop: "0px",
                }}
              >
                <Typography variant="h4" sx={customContentStyle}>
                  {containsHTMLTags(props?.contentText)
                    ? parse(props?.contentText)
                    : props?.contentText}
                </Typography>
              </Box>
            ) : null}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {props?.isCreateNewTag || props?.isEditTag ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: "20px",
                paddingTop: "0px",
              }}
            >
              <CustomButton
                text={props?.isEditTag ? "Update Tag" : "Create Tag"}
                handleOnClick={handleClickOk}
                height="40px"
                width="100% !important"
              >
                {props?.isSending ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#FFFFFF", marginRight: "10px" }}
                  />
                ) : null}
              </CustomButton>
            </Box>
          ) : !props?.isViewTag ? (
            <Box sx={footerStyles}>
              <CustomButton
                text={props?.OKText}
                handleOnClick={handleClickOk}
                fontSize={width < 540 ? "13px" : "14px"}
                height="40px"
                color="#C6281F"
                backgroundColor={props?.isDeleteTag && "#E94B421A"}
              >
                {props?.isSending ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#C6281F", marginRight: "10px" }}
                  />
                ) : null}
              </CustomButton>
              <CustomButton
                text={props?.cancelText}
                handleOnClick={handleClose}
                fontSize={"14px"}
                height="40px"
                backgroundColor={"#E94B42"}
              />
            </Box>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomPopup;
