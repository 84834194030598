import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Decimal from "decimal.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Spinner } from "react-bootstrap";
import { Box, IconButton, Pagination } from "@mui/material";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Modaltable from "../components/modal-table/mTable";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { TbArrowBarToDown } from "react-icons/tb";
import { Table } from "antd";
import {
  getAllContacts,
  addContact,
  addMultipleContacts,
  deleteContacts,
  searchContact,
} from "../services/Contacts/index";
import { updatetheContact } from "../services/Contacts/index";
import Companyactionbtn from "../components/company-listing/compnay-actionbtn";
import Papa from "papaparse";
import WindowWidthDetector from "../components/customHooks/DeviceWidthDetector";
import moment from "moment";
import ContactgroupTable from "../components/contact-table/c-table";
import Search from "../components/search/search";
import { getUserDetails } from "../services/User";
import { toast } from "react-toastify";
import Select from "react-select";
import { getAllGroups, searchGroup } from "../services/Group";
import { exportToExcel } from "../utils/exportToCsv";
import { countryCodeList } from "../utils/countryCodeList";
import TableSkeleton from "../components/skeletons/TableLoader";
import { Typography } from "@mui/material";
import ContactIcon from "../images/contact-icon.svg";
import UploadIcon from "../images/upload-icon.svg";
import InvalidIcon from "../images/invalid-contact.svg";
import AddIcon from "../images/contact-mobile-add-icon.svg";

import Wtable from "../components/table/table";
import ContactMobile from "../components/contact-table/ContactMobile";
import { setContactsData } from "../reduxSlice/contacts";
import Groups from "./group";
import { setAllGroups, setSelectedFilterGroup } from "../reduxSlice/groups";

const Contact = () => {
  const { allContacts, totalRecords } = useSelector(
    (state) => state.ContactsReducer
  );

  const { allGroupsFilter, selectedFilterGroup } = useSelector(
    (state) => state.GroupsReducer
  );

  const dispatch = useDispatch();
  console.log("allContacts", allContacts);
  const width = WindowWidthDetector();
  const [ctshow, setCtShow] = useState(false);
  const [cvsshow, setCvsShow] = useState(false);
  const [inValidContacs, setAllInValidContacts] = useState([]);
  const [isInvalidRecords, setIsInvalidRecords] = useState(false);
  const [csvError, setCsvError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [newContactData, setNewContactData] = useState({
    contactName: "",
    number: "",
    groupName: "",
    canMessage: true,
    canBroadcast: true,
  });
  const [isActive, setIsActive] = useState(true);
  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState(null);

  const [fileUploaded, setFileUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({
    totalPages: totalRecords ? totalRecords : 1,
    currPage: 1,
  });
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [contactsGroup, setContactsGroup] = useState([]);
  const [isNewContactAdded, setIsNewContactAdded] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [duplicateNames, setDuplicateNames] = useState([]);
  const [invalidRecordsLength, setInvalidRecordsLength] = useState(0);
  const [duplicateNumbers, setDuplicateNumbers] = useState([]);
  const [inserted, setInserted] = useState([]);
  const [allOrgnlRecords, setOrgRecords] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [updateContacts, setUpdateContacts] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [searchText, setSearchText] = useState(""); // Filter by search text
  const [isGroupImport, setIsGroupImport] = useState(false);
  const [addContactResponse, setAddContactResponse] = useState(null);
  const [importContactResponse, setImportContactResponse] = useState(null);

  const [inputErrors, setInputErrors] = useState({
    contactName: { hasError: false, errorMessage: "" },
    number: { hasError: false, errorMessage: "" },
    groupName: { hasError: false, errorMessage: "" },
  });
  const [groupName, setGroupName] = useState("");
  const [isNewGroup, setIsNewGroup] = useState(false);
  const [groups, setGroups] = useState([]);
  const numericRegex = /^[0-9]+$/;
  // const nameRegex = /^[A-Za-z0-9]+(?:[-' ][A-Za-z0-9]+)*$/;
  const nameRegex = /^(?!\s*$).+/;
  const [searchQuery, setSearchQuery] = useState("");
  const [importGroups, setImportGroups] = useState([]);
  const [isNoGroup, setIsNoGroup] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const inputRef = useRef(null);
  const [currPage, setCurrPage] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // const [allGroups, setAllGroups] = useState(null);
  // const [selectedFilterGroup, setSelectedFilterGroup] = useState(null);

  const [isExisting, setIsExisting] = useState(true);
  const [showContactSuccess, setShowContactSuccess] = useState(false);
  const [showImportSuccess, setShowImportSuccess] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value === "true";
    console.log("Selected:", value);
    setIsExisting(value);
  };

  const tabContainerStyles = {
    border: "1px solid #D9D9D9",
    borderRadius: "6px",
    padding: "2px",
    backgroundColor: "white",
    minWidth: "212px",
  };
  const tabStyles = {
    padding: "7.5px 11px",

    cursor: "pointer",
    fontWeight: "600",
    fontSize: "14px",
    width: "100%",
    textAlign: "center",
    fontFamily: "Segoe UI",
  };
  const activeUsersStyle = {
    // borderTopLeftRadius: "6px",
    // borderBottomLeftRadius: "6px",
    borderRadius: "4px",
    backgroundColor: isActive ? "#165E5B" : "white",
    color: isActive ? "white" : "#165E5BE5",
  };

  const deletedUsersStyles = {
    // borderTopRightRadius: "6px",
    // borderBottomRightRadius: "6px",
    borderRadius: "4px",

    backgroundColor: !isActive ? "#165E5B" : "white",
    color: !isActive ? "white" : "#165E5BE5",
  };

  const groupStyles = {
    backgroundColor: "#BCF1D54D",
    padding: "7px 8px",
    borderRadius: "6px",
    fontSize: "14px",
    fontWeight: "400",
  };
  const groupContainerStyles = {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    maxWidth: "450px",
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "#000000",
      fontWeight: 500,
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": { backgroundColor: "#E2FFF0", color: "#000000" },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2, // Ensures menu appears above other elements
      backgroundColor: "white",
    }),
  };

  useEffect(() => {
    // Function to update screenWidth state
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", updateScreenWidth);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const handleClick = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setLoading(true)
        const resp = await getAllGroups(1, 1000);
        const groupData = [];
        if (resp.success) {
          resp.data.map((data) => {
            groupData.push({ value: data._id, label: data.groupName });
          });
        }

        setGroups(groupData);
      } catch (error) {
        console.log("Failed to fetch Groups!");
      }
    };
    fetchData();
  }, [ctshow, cvsshow]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputErrors((prevState) => ({
      ...prevState,
      [name]: { hasError: false, errorMessage: "" },
    }));
    setNewContactData((prevValues) => ({ ...prevValues, [name]: value }));

    setSelectedContact((prevValues) => ({ ...prevValues, [name]: value }));
  };

  useEffect(() => {
    if (!ctshow) {
      setNewContactData({
        contactName: "",
        number: "",
        groupName: "",
        canMessage: true,
        canBroadcast: true,
      });
      setInputErrors({
        contactName: { hasError: false, errorMessage: "" },
        number: { hasError: false, errorMessage: "" },
        groupName: { hasError: false, errorMessage: "" },
      });
    }
  }, [ctshow]);

  useEffect(() => {
    if (!isInvalidRecords) {
      setIsAdding(false);
      setFileData(null);
      setFileName("");
    }
  }, [isInvalidRecords]);

  useEffect(() => {
    if (!cvsshow) {
      setContactsGroup([]);
    }
  }, [cvsshow]);

  // setting data of contacts
  const setAllContactsData = (contactsData, records = totalRecords) => {
    console.log("data of contacts got :", contactsData);

    // Set original data (raw contacts)
    setOrgData(contactsData);

    // Transform the data for the table and update contacts
    const transformedData = handleDataForTable(contactsData);
    setContacts(transformedData);
    setOrgRecords(transformedData);

    // Dispatch the contacts data along with total records to Redux store
    dispatch(setContactsData({ data: contactsData, totalRecords: records }));
  };
  console.log("selectedFiltereGroup", selectedFilterGroup);
  const fetchAllContacts = async (page = 1, pageSize = 20, search = "") => {
    console.log("called", page);
    setLoading(true);
    try {
      let contactsData = [];
      // const resp = await getAllContacts(page, pageSize);
      const resp = await searchContact(
        page,
        pageSize,
        search,
        selectedFilterGroup?.value ?? "",
        selectedFilterGroup?.value == "noGroup" ? true : false
      );

      if (resp.success) {
        const { data, totalRecords, totalPages } = resp;

        contactsData = [...data]; // Append new data

        setAllContactsData(contactsData, totalRecords);
        setPaginationData({
          currPage: page,
          totalPages: totalPages,
        });
        setCurrentPage(page);
      } else {
        throw new Error(resp.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchAllGroups = async (page = 1, pageSize = "10000", search = "") => {
    try {
      let groupsData = [];
      const resp = await searchGroup(page, pageSize, search);

      if (resp.success) {
        const { data, totalRecords } = resp;

        data?.forEach((d) =>
          groupsData.push({ value: d?._id, label: d?.groupName })
        );
        groupsData.unshift({
          value: "all",
          label: "All Groups",
        });
        groupsData.splice(1, 0, {
          value: "noGroup",
          label: "Not Associated",
        });
        // setAllGroups(groupsData);
        dispatch(setAllGroups(groupsData));
      } else {
        throw new Error(resp.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // groups effect for dropdown
  useEffect(() => {
    fetchAllGroups();
  }, [newContactData]);
  useEffect(() => {
    if (!cvsshow) setFileUploaded(false);
  }, [cvsshow]);

  const handleClose = () => {
    setContactsGroup([]);
    setInserted([]);
    setSelectedContacts([]);
    setDuplicateNumbers([]);
    setDuplicateNames([]);
    setCtShow(false);
    setSelectedGroups([]);
  };

  const handleDelete = async (id) => {
    const isDeleted = await deleteContacts(id);
    if (isDeleted?.success) {
      getSearchedRecords("");
      toast.success("Contact Deleted Successfully!");
      console.log("new pagination", paginationData);
      // setPaginationData({
      //   currPage:
      //     paginationData.currPage > paginationData.totalPages
      //       ? paginationData.currPage - 1
      //       : paginationData.currPage,
      //   totalPages: paginationData.totalPages,
      // });
      fetchAllContacts(paginationData.currPage, 20, searchText);

      // fetchAllContacts();
    } else {
      console.log("Something went wrong");
    }
  };
  // console.log("new pagination1", paginationData);
  const isPhoneNumValid = () => {
    const num = newContactData.number;
    const name = newContactData.contactName;
    if (!nameRegex.test(name)) {
      setInputErrors((prevState) => ({
        ...prevState,
        contactName: {
          ...prevState.contactName,
          hasError: true,
          errorMessage: "Enter a valid name",
        },
      }));
      return false;
    } else if (!numericRegex.test(num)) {
      setInputErrors((prevState) => ({
        ...prevState,
        number: {
          ...prevState.number,
          hasError: true,
          errorMessage: "Enter a valid contact number",
        },
      }));
      return false;
    } else {
      const countryNumberDetail = countryCodeList.filter((code) => {
        if (num.startsWith(code?.phone)) return code;
      });
      console.log("countryNumberDetail", countryNumberDetail);
      if (countryNumberDetail.length <= 0) {
        setInputErrors((prevState) => ({
          ...prevState,
          number: {
            ...prevState.number,
            hasError: true,
            errorMessage: `Invalid country code.`,
          },
        }));
        return false;
      }
      const totalLength =
        countryNumberDetail[0]?.phone.length +
        countryNumberDetail[0]?.phoneLength;
      if (countryNumberDetail?.length > 0) {
        console.log("thiss");

        if (num.length === totalLength) {
          return true;
        }
      }
      setInputErrors((prevState) => ({
        ...prevState,
        number: {
          ...prevState.number,
          hasError: true,
          errorMessage: `Phone number length for ${countryNumberDetail[0]?.label} is ${totalLength} `,
        },
      }));
      return false;
    }
    // else if (num.length < 7) {
    //     setInputErrors(prevState => ({
    //         ...prevState,
    //         number: {
    //             ...prevState.number,
    //             hasError: true,
    //             errorMessage: "Phone number cannot be less than 7 digits"
    //         }
    //     }));
    //     return false
    // }
    // else if (num.length > 15) {
    //     setInputErrors(prevState => ({
    //         ...prevState,
    //         number: {
    //             ...prevState.number,
    //             hasError: true,
    //             errorMessage: "Phone number cannot be greater than 15 digits"
    //         }
    //     }));
    //     return false
    // }
    // return true
  };
  console.log("selectedContact", selectedContact);
  useEffect(() => {
    if (selectedContact?.groups) {
      setSelectedGroups(selectedContact.groups); // Pre-fill select
    }
  }, [selectedContact]);

  const isPhoneNumValidForUpdate = () => {
    console.log("selectedContact", selectedContact);
    const num = selectedContact.number;
    const name = selectedContact.contactName;
    if (!nameRegex.test(name)) {
      setInputErrors((prevState) => ({
        ...prevState,
        contactName: {
          ...prevState.contactName,
          hasError: true,
          errorMessage: "Enter a valid name",
        },
      }));
      return false;
    } else if (!numericRegex.test(num)) {
      setInputErrors((prevState) => ({
        ...prevState,
        number: {
          ...prevState.number,
          hasError: true,
          errorMessage: "Enter a valid contact number",
        },
      }));
      return false;
    } else {
      const countryNumberDetail = countryCodeList.filter((code) => {
        if (num.startsWith(code.phone)) return code;
      });
      const totalLength =
        countryNumberDetail[0].phone.length +
        countryNumberDetail[0].phoneLength;
      if (countryNumberDetail.length > 0) {
        if (num.length === totalLength) {
          return true;
        }
      }
      setInputErrors((prevState) => ({
        ...prevState,
        number: {
          ...prevState.number,
          hasError: true,
          errorMessage: `Phone number length for ${countryNumberDetail[0].label} is ${totalLength} `,
        },
      }));
      return false;
    }
  };
  console.log("selectedGroups", inputErrors);
  const handleAddContacts = async (e) => {
    e?.preventDefault();

    if (!fileData) {
      setCsvError(true);
      return;
    }

    if (isExisting) {
      if (selectedGroups?.length > 0) {
        const groupIds = selectedGroups.map((group) => group?.value);
        setImportGroups(groupIds);
        setIsNewGroup(false);
      } else {
        setInputErrors((prevState) => ({
          ...prevState,
          groupName: {
            ...prevState.groupName,
            hasError: true,
            errorMessage: "Select existing group or create new group",
          },
        }));
        return;
      }
    } else {
      if (newContactData?.groupName) {
        setIsNewGroup(true);

        setImportGroups([]);
        setGroupName(newContactData?.groupName);
      } else {
        setInputErrors((prevState) => ({
          ...prevState,
          groupName: {
            ...prevState.groupName,
            hasError: true,
            errorMessage: "Select existing group or create new group",
          },
        }));
        return;
      }
    }
    setIsAdding(true);
    let contacts = [],
      inValidContacts = [];

    // Clear previous states
    setContactsGroup([]);
    setIsInvalidRecords(false);
    setAllInValidContacts([]);

    // Parse the CSV file
    Papa.parse(fileData, {
      header: false,
      skipEmptyLines: true,
      complete: function (results) {
        if (
          !results.data[0][0] ||
          !results.data[0][1] ||
          results.data[0]?.length < 2 ||
          results.data[0][0] !== "name" ||
          results.data[0][1] !== "number"
        ) {
          toast.error(
            "The headers are incorrect or missing.Please use the sample file."
          );
          setIsAdding(false);
          return;
        }

        results.data.slice(1).forEach((item) => {
          let name = item[0];
          let number = item[1];
          let element = {
            contactName: name,
            number,
            invalid: false,
            reason: "",
          };
          if (!name?.trim() || !number?.trim()) {
            // return; // Skip empty rows
            element.invalid = true;
            element.reason = "Name or number can't be empty";
            inValidContacts.push(element);
            return;
          }

          // Check for exponential form or invalid number format
          if (!isNaN(number) && (number.includes("e") || !number.trim())) {
            // toast.error(
            //   "Invalid number format (e.g., exponential form). Use the sample file."
            // );
            element.invalid = true;
            element.reason = "Invalid number format";
            inValidContacts.push(element);
            return;
            // setIsAdding(false);
          }

          if (!nameRegex.test(element.contactName)) {
            element.invalid = true;
            element.reason = "Invalid name format";
            inValidContacts.push(element);
            return;
          }
          if (!isNaN(number) && (number.includes("e") || !number.trim())) {
            element.invalid = true;
            element.reason =
              "Invalid number format (e.g., exponential form). Use the sample file.";
            inValidContacts.push(element);
            return;
            // setIsAdding(false);
          }

          // Validate number
          if (!numericRegex.test(element.number)) {
            element.invalid = true;
            element.reason = "Invalid number format";
            inValidContacts.push(element);
            return;
          }

          // Validate name and number
          // if (
          //   !numericRegex.test(element.number) ||
          //   !nameRegex.test(element.contactName)
          // ) {
          //   element.invalid = true;
          // }

          // Country code validation
          const countryNumberDetail = countryCodeList.filter((code) => {
            return number.startsWith(code.phone);
          });
          if (countryNumberDetail.length === 0) {
            element.invalid = true;
            element.reason = "Country code not recognized";
            inValidContacts.push(element);
            return;
          } else if (
            number.length !==
            countryNumberDetail[0].phone.length +
              countryNumberDetail[0].phoneLength
          ) {
            element.invalid = true;
            element.reason = "Number length does not match country code";
            inValidContacts.push(element);
            return;
          }

          // if (
          //   countryNumberDetail.length === 0 ||
          //   number.length !==
          //     countryNumberDetail[0].phone.length +
          //       countryNumberDetail[0].phoneLength
          // ) {
          //   element.invalid = true;
          // }

          // Separate valid and invalid contacts
          // if (element.invalid) {
          //   inValidContacts.push(element);
          // } else {
          contacts.push(element);
          // }
        });
        setAllInValidContacts(inValidContacts);
        setIsInvalidRecords(inValidContacts.length > 0);
        setInvalidRecordsLength(inValidContacts.length);

        if (inValidContacts.length > 0) {
          setCvsShow(false);
          return;
        }

        setContactsGroup(contacts); // This will trigger the useEffect below
      },
    });

    setIsAdding(false);
  };

  // Add useEffect to trigger API call when contactsGroup is updated
  useEffect(() => {
    const addContacts = async () => {
      if (contactsGroup.length > 0) {
        setIsAdding(true);

        const allNonEmptyRecords = contactsGroup.filter(
          (item) => item.contactName.trim() !== "" && item.number !== 0
        );

        try {
          const resp = await addMultipleContacts({
            contacts: allNonEmptyRecords,
            groupName,
            isNewGroup,
            groups: importGroups,
          });

          if (resp.success) {
            setImportContactResponse(resp?.data);
            setFileData(null);
            setFileName("");
            setIsInvalidRecords(false);
            setCvsShow(false);
            handleClose();
            setShowImportSuccess(true);
            setContacts((prevContact) => [
              ...resp.data.inserted,
              ...prevContact,
            ]);
            fetchAllContacts();
            setUpdateContacts((prev) => !prev);
            // toast.success(
            //   `${
            //     resp.data.inserted.length + resp.data.updateContacts.length
            //   } new contacts added & ${
            //     resp.data.duplicateNumbers.length
            //   } duplicate contacts found`
            // );
          } else {
            throw new Error(resp.error);
          }
        } catch (error) {
          toast.error(
            "Something went wrong. Please fix contacts in the CSV file."
          );
          console.log(error.message);
        } finally {
          setIsAdding(false);
        }
      }
    };

    addContacts();
  }, [contactsGroup]); // Trigger when contactsGroup changes

  const handleAddContact = async (e) => {
    e.preventDefault();

    try {
      console.log("selectedGroups", selectedGroups);
      const check = isPhoneNumValid();

      if (!check) return;
      if (isExisting) {
        if (selectedGroups?.length > 0) {
          newContactData.groups = selectedGroups.map((group) => group?.value);
          newContactData.isNewGroup = false;
        } else {
          setInputErrors((prevState) => ({
            ...prevState,
            groupName: {
              ...prevState.groupName,
              hasError: true,
              errorMessage: "Select existing group or create new group",
            },
          }));
          return;
        }
      } else {
        if (newContactData?.groupName) {
          newContactData.isNewGroup = true;

          newContactData.groups = [];
        } else {
          setInputErrors((prevState) => ({
            ...prevState,
            groupName: {
              ...prevState.groupName,
              hasError: true,
              errorMessage: "Select existing group or create new group",
            },
          }));
          return;
        }
      }
      setIsAdding(true);

      // if (selectedGroups?.length > 0) {

      // }
      const resp = await addContact(newContactData);
      if (resp.success) {
        setAddContactResponse(resp?.data);
        console.log("Contact Added successfully");
        //setContacts(prevContact => [resp.data, ...prevContact])
        handleClose();
        setShowContactSuccess(true);
        // toast.success("Contact Added successfully");
        console.log("currentPAge", currentPage);
        // fetchAllContacts(currentPage, 10, searchText);
        fetchAllContacts(
          paginationData.currPage > paginationData.totalPages &&
            paginationData.totalPages > 0
            ? paginationData.currPage - 1
            : paginationData.currPage,
          20,
          searchText
        );
        setPaginationData({
          currPage:
            paginationData.currPage > paginationData.totalPages &&
            paginationData.totalPages > 0
              ? paginationData.currPage - 1
              : paginationData.currPage,
          totalPages: paginationData.totalPages,
        });
      } else {
        // toast.error(resp.error);
        throw new Error(resp.error);
      }
    } catch (error) {
      setIsAdding(false);
      toast.error(error.message);
      handleClose();

      console.log(error.message);
      if (error.message.includes("Enter a valid contact name")) {
        setIsAdding(false);

        setInputErrors((prevState) => ({
          ...prevState,
          contactName: {
            ...prevState.contactName,
            hasError: true,
            errorMessage: "Enter a valid contact name",
          },
        }));
      }
      if (error.message.includes("A contact with this name already exists")) {
        setIsAdding(false);

        setInputErrors((prevState) => ({
          ...prevState,
          contactName: {
            ...prevState.contactName,
            hasError: true,
            errorMessage: "A contact with this name already exists",
          },
        }));
      }
      if (error.message.includes("A contact with this number already exists")) {
        setIsAdding(false);

        setInputErrors((prevState) => ({
          ...prevState,
          number: {
            ...prevState.number,
            hasError: true,
            errorMessage: "A contact with this number already exists",
          },
        }));
      }
      if (error.message.includes("Enter a valid phone number")) {
        setIsAdding(false);

        setInputErrors((prevState) => ({
          ...prevState,
          number: {
            ...prevState.number,
            hasError: true,
            errorMessage: "Enter a valid contact number",
          },
        }));
      }
    }
    setIsAdding(false);
  };
  console.log("paginationData", paginationData);
  const handleEdit = async () => {
    const check = isPhoneNumValidForUpdate();
    console.log("updatedContact: ", selectedContact);
    console.log("check", check);
    if (!check) return;
    const updatedContact = {
      ...selectedContact,
      groups: selectedGroups?.map((group) => group.value),
    };
    const isContactUpdated = await updatetheContact(updatedContact);
    if (isContactUpdated?.success) {
      console.log("Contact Successfully Updated!");
      setUpdateContacts((prevUpdateContacts) => !prevUpdateContacts);

      setEditContact(false);
      setSelectedContact(null);

      toast.success("Contact Updated Successfully");
      fetchAllContacts(
        paginationData.currPage > paginationData.totalPages
          ? paginationData.currPage - 1
          : paginationData.currPage,
        20,
        searchText
      );
      setPaginationData({
        currPage:
          paginationData.currPage > paginationData.totalPages
            ? paginationData.currPage - 1
            : paginationData.currPage,
        totalPages: paginationData.totalPages,
      });
    } else {
      console.log("Something went wrong");
      toast.error(isContactUpdated?.error);
      return isContactUpdated;
    }
    console.log(isContactUpdated);
  };

  let isHeaderWrong = true;

  const changeHandler = (event) => {
    const file = event.target.files[0];
    console.log("renderrrrrr", event.target.files[0]);
    console.log(
      "invalidRecordsss",
      invalidRecordsLength,
      "::",
      isInvalidRecords
    );

    if (file) {
      setCsvError(false);
    }
    if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
      toast.error("Please upload a CSV file.");
      return;
    }
    setFileData(file);
    setFileName(file.name);
  };

  const exportContact = async () => {
    const resp = await searchContact(
      1,
      100000,
      searchText,
      selectedFilterGroup?.value ?? "",
      selectedFilterGroup?.value == "noGroup" ? true : false
    );
    console.log("ssssss", resp.data);
    const result = resp?.data.map((contact) => ({
      name: contact.contactName,
      number: contact.number,
    }));
    exportToExcel(result);
    toast.success("Contacts Exported Sucessfully");
  };
  useEffect(() => {
    console.log("effect runs");
    if (isActive) fetchAllContacts(currentPage, 20, searchText);

    setCurrentPage(currentPage);
  }, [currentPage, isActive, selectedFilterGroup]);

  // useEffect(() => {
  //   fetchAllContacts();
  // }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
    },
    {
      title:
        // <p style={{ textAlign: "center", fontSize: "14px", fontWeight: "600" }}>
        "Member Of Group(s)",
      // </p>
      dataIndex: "member",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
    },
    {
      title: "Actions",
      dataIndex: "Actions",
    },
  ];
  console.log("isInvalidRecords", isInvalidRecords);

  const getRandomColor = (index) => {
    // Calculate hue value based on the index to get distinct colors
    const hue = (index * 50) % 360; // You can adjust the multiplier (50) to change the color range

    // Set constant saturation and lightness values
    const saturation = 50;
    const lightness = 50;

    // Convert HSL to RGB values
    const hslToRgb = (h, s, l) => {
      h /= 360;
      s /= 100;
      l /= 100;
      let r, g, b;

      if (s === 0) {
        r = g = b = l; // Achromatic
      } else {
        const hue2rgb = (p, q, t) => {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1 / 6) return p + (q - p) * 6 * t;
          if (t < 1 / 2) return q;
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
          return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
      }

      const toHex = (x) => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      };

      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };

    return hslToRgb(hue, saturation, lightness);
  };

  // making data for showing rows in table
  const handleDataForTable = (dataArray) => {
    let finalData = [];
    for (let i = 0; i != dataArray.length; i++) {
      const data = dataArray[i];

      // Map groups to the desired format
      const editedGroups = data?.groups?.map((group) => ({
        label: group?.groupName,
        value: group?._id,
      }));

      // Create a new data object with the transformed groups array
      const newData = { ...data, groups: editedGroups };
      console.log("newData", newData);
      const color = getRandomColor(i);
      finalData.push({
        key: newData._id,
        name: newData?.contactName,
        number: newData?.number,
        Name: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className="random-clr"
              style={{ background: color, marginRight: "10px" }}
            >
              {newData?.contactName?.substring(0, 1)}
            </div>
            <p
              style={{
                fontFamily: "Segoe UI",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "22px",
              }}
            >
              {newData?.contactName}
            </p>
          </div>
        ),
        createdAt: (
          <p
            style={{
              fontFamily: "Segoe UI",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "22px",
            }}
          >
            {moment(newData?.createdAt).format("DD MMM, YYYY")}
          </p>
        ),
        phone: (
          <p
            style={{
              fontFamily: "Segoe UI",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "22px",
            }}
          >
            {"+" + newData?.number}
          </p>
        ),
        member: (
          <div style={groupContainerStyles}>
            {newData?.groups?.length <= 0 ? (
              <p
              //  style={{ textAlign: "center", width: "100%" }}
              >
                -
              </p>
            ) : (
              newData?.groups?.map((group) => {
                return <span style={groupStyles}> {group?.label ?? "-"}</span>;
              })
            )}
          </div>
        ),
        Actions: (
          <Companyactionbtn
            data={newData}
            isComingFromContact
            submitDelete={handleDelete}
            fetchAllContacts={fetchAllContacts}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            submitEdit={() => {
              setEditContact(true);
              setSelectedContact(newData);
              setInputErrors({
                contactName: { hasError: false, errorMessage: "" },
                number: { hasError: false, errorMessage: "" },
                groupName: { hasError: false, errorMessage: "" },
              });
            }}
            setSearchText={setSearchText}
          />
        ),
      });
    }

    return finalData;
  };

  console.log("contacts", contacts);
  // Function to download the sample CSV
  const downloadSampleCSV = () => {
    // Define CSV content with headers "name" and "number"
    const csvContent = `"name","number"\n"Contact name","971661111111"\n`;

    // Create a blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element and trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "sample.csv"); // The downloaded file will be named 'sample.csv'

    // Trigger the download by clicking the link programmatically
    document.body.appendChild(link);
    link.click();

    // Clean up the DOM by removing the link element
    document.body.removeChild(link);
  };

  // search any record
  const getSearchedRecords = async (value) => {
    setSearchText(value);
    await fetchAllContacts(1, 20, value);
  };

  const handleTryAgain = () => {
    setCvsShow(true);
    setIsInvalidRecords(false);
    setFileName("");
    setFileData(null);
    setIsAdding(false);
    setAllInValidContacts([]);
    setInvalidRecordsLength(0);
  };

  const handlePaginationChange = (event, value) => {
    console.log("valiue", value);
    fetchAllContacts(value, 20, searchText);
    setCurrentPage(value); // Fetch new data for the selected page
    setPaginationData({
      currPage: value,
      totalPages: paginationData.totalPages,
    });
  };
  console.log(
    "invalidRecordsLength",
    invalidRecordsLength,
    "::",
    isInvalidRecords,
    "::",
    inValidContacs
  );
  return (
    <section className="main inner-main">
      {screenWidth >= 1024 ? (
        <section className="contact-group-top mb-3">
          <Container className="ps-0 pe-0">
            <Row className="flex-column-reverse flex-md-row align-items-center">
              <Col xs={6} md={6} className="mb-3 mb-md-0">
                <div className="mx-lg-0 d-flex gap-3">
                  <h2
                    className="d-flex align-items-center gap-2 m-0"
                    style={{
                      fontWeight: "600",
                      fontSize: "22px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    <img src={ContactIcon} alt="Contact Icon" />
                    Contacts
                  </h2>
                  <div style={tabContainerStyles} className="d-flex">
                    <div
                      style={{
                        ...tabStyles,
                        ...activeUsersStyle,
                      }}
                      onClick={() => {
                        setIsActive(true);
                        setLoading(true);
                      }}
                    >
                      All Contacts
                    </div>
                    <div
                      style={{
                        ...tabStyles,
                        ...deletedUsersStyles,
                      }}
                      onClick={() => {
                        setIsActive(false);
                      }}
                    >
                      Groups
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={6} md={6}>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <Button
                    className="btn-main d-flex align-items-center"
                    onClick={exportContact}
                    style={{ minWidth: "200px" }}
                  >
                    <TbArrowBarToDown
                      size="20"
                      style={{ marginRight: "10px" }}
                    />
                    Export Contacts
                  </Button>
                  <Button
                    className="btn-main d-flex align-items-center"
                    onClick={() => setCtShow(true)}
                  >
                    <i className="bi bi-plus"></i>
                    Add Contact
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        <section className="contact-group-top">
          <Container className="">
            <h2
              className="mt-1 flex gap-2 mb-3 "
              style={{
                fontWeight: "600",
                fontSize: "22px",
                fontFamily: "Segoe UI",
              }}
            >
              <img src={ContactIcon} width={24} height={24} />
              Contacts
            </h2>
            <div style={tabContainerStyles} className="d-flex mb-3">
              <div
                style={{
                  ...tabStyles,
                  ...activeUsersStyle,
                }}
                onClick={() => {
                  setIsActive(true);
                  setLoading(true);
                }}
              >
                All Contacts
              </div>
              <div
                style={{
                  ...tabStyles,
                  ...deletedUsersStyles,
                }}
                onClick={() => {
                  setIsActive(false);
                }}
              >
                Groups
              </div>
            </div>

            <div className="d-grid gap-2 d-flex justify-content-start mb-3">
              <Button
                className="btn-main d-flex align-items-center"
                onClick={exportContact}
              >
                <TbArrowBarToDown size="20" style={{ marginRight: "10px" }} />
                Export Contacts
              </Button>
              <Button
                className="btn-main d-flex align-items-center"
                onClick={() => setCtShow(true)}
              >
                <i className="bi bi-plus"></i>
                Add Contact
              </Button>
            </div>
          </Container>
        </section>
      )}

      <Box className="bg-[#ecf1f9] flex justify-start align-items-start w-100 flex-column h-100">
        {isActive &&
          (screenWidth >= 1024 ? (
            <Container className="ps-0 pe-0">
              <div className="contact-top d-flex align-items-center justify-content-between">
                <Form.Group
                  className="input-search "
                  style={{ minWidth: "233px" }}
                >
                  <Form.Control
                    className="py-4"
                    type="text"
                    placeholder="Search contact"
                    // onChange={(e) =>  setSearchQuery(e.target.value.trimStart())}
                    onChange={(e) =>
                      getSearchedRecords(e.target.value.trimStart())
                    }
                    value={searchText}
                    style={{ minWidth: "100%" }}
                  />
                </Form.Group>
                <Form.Group
                  className="input-search"
                  style={{ minWidth: "233px" }}
                >
                  <Select
                    className="c-select basic-multi-select"
                    options={allGroupsFilter}
                    placeholder={"All Groups"}
                    styles={customStyles}
                    classNamePrefix="select"
                    onChange={(e) => {
                      dispatch(setSelectedFilterGroup(e));
                    }}
                    value={selectedFilterGroup}
                    components={{
                      DropdownIndicator: () => (
                        <svg
                          width="10"
                          height="5"
                          viewBox="0 0 10 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M0 0L5 5L10 0H0Z" fill="#494949" />
                        </svg>
                      ),
                    }}
                  />
                </Form.Group>
              </div>
            </Container>
          ) : (
            <Container className="">
              <div className="contact-top  w-100">
                <Form.Group className="input-search w-100">
                  <Form.Control
                    className="py-4"
                    type="text"
                    placeholder="Search contact"
                    onChange={(e) =>
                      getSearchedRecords(e.target.value.trimStart())
                    }
                    value={searchText}
                    style={{ minWidth: "100%" }}
                  />
                </Form.Group>
                <Form.Group className="input-search w-100 mt-2">
                  <Select
                    className="c-select basic-multi-select"
                    options={allGroupsFilter}
                    placeholder={"All Groups"}
                    styles={customStyles}
                    classNamePrefix="select"
                    onChange={(e) => {
                      dispatch(setSelectedFilterGroup(e));
                    }}
                    value={selectedFilterGroup}
                    components={{
                      DropdownIndicator: () => (
                        <svg
                          width="10"
                          height="5"
                          viewBox="0 0 10 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M0 0L5 5L10 0H0Z" fill="#494949" />
                        </svg>
                      ),
                    }}
                  />
                </Form.Group>
              </div>
            </Container>
          ))}
        {loading ? (
          <TableSkeleton />
        ) : isActive ? (
          screenWidth >= 1023 ? (
            <Box
              className="flex justify-start align-items-start w-100 mt-3 rounded-md container all-tags mb-8 testing"
              style={{
                padding: 0,
                height: "80%",
                marginBottom: "100px",
              }}
            >
              <Table
                columns={columns}
                dataSource={contacts} // Use full contacts data, not sliced
                pagination={
                  false // Disable changing pageSize (optional)
                }
                style={{ width: "100%", background: "#FFFFFF" }}
                size="middle"
              />
            </Box>
          ) : (
            <>
              <section className=" pt-3 d-lg-none w-100">
                <ContactMobile contacts={contacts} />
              </section>
            </>
          )
        ) : (
          <Groups setCsvShow={setCvsShow} setIsGroupImport={setIsGroupImport} />
        )}
        {isActive && (
          <Box
            className="bg-[#FFFFFF] flex justify-center align-items-center w-100 all-tags "
            sx={{
              height: "70px",
              position: "fixed",
              bottom: "0",
              boxShadow: "0 -4px 6px rgba(0, 0, 0, 0.1)",
              marginTop: "100px",
            }}
          >
            {contacts?.length > 0 && (
              <Pagination
                count={paginationData.totalPages} // Total number of pages
                variant="outlined"
                shape="rounded"
                siblingCount={2}
                page={paginationData.currPage} // Current page
                onChange={handlePaginationChange} // Page change handler
              />
            )}
          </Box>
        )}
      </Box>

      <Modal
        show={ctshow}
        onHide={() => {
          setCtShow(false);
          setSelectedGroups([]);
          setInputErrors((prevState) => ({
            ...prevState,
            groupName: {
              ...prevState.groupName,
              hasError: false,
              errorMessage: "",
            },
          }));
        }}
        className="whatsapp-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title style={{ marginBottom: "8px" }}>Add Contact</Modal.Title>
          <Modal.Title
            onClick={() => {
              setCtShow(false);
              setCvsShow(true);
            }}
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#165E5B",
              fontFamily: "Segoe UI",
              marginBottom: "8px",
              cursor: "pointer",
            }}
          >
            Import Contacts
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  name="contactName"
                  value={newContactData.contactName}
                  onChange={handleInputChange}
                  className={
                    inputErrors.contactName.hasError ? "is-invalid" : ""
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.contactName.hasError &&
                    inputErrors.contactName.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Phone No*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex: 447700900123 "
                  name="number"
                  value={newContactData.number}
                  onChange={handleInputChange}
                  className={inputErrors.number.hasError ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.number.hasError &&
                    inputErrors.number.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Add to*</Form.Label>
                <div className="button-select mt-[16px]">
                  <label className="form-check form-check-inline">
                    <input
                      className="form-check-input create-template-btn"
                      type="radio"
                      id="inline-radio-1"
                      name="category"
                      value="true"
                      onChange={handleChange}
                      checked={isExisting}
                    />
                    <span
                      className="form-label"
                      style={{
                        color: isExisting ? "#165E5B" : "#4B4A4A",
                        fontSize: "14px",
                        fontFamily: "Segoe UI",
                        fontWeight: 500,
                      }}
                      htmlFor="inline-radio-1"
                    >
                      Existing Group
                    </span>
                  </label>
                  <label className="form-check form-check-inline">
                    <input
                      className="form-check-input create-template-btn"
                      type="radio"
                      id="inline-radio-1"
                      name="category"
                      value="false"
                      onChange={handleChange}
                      checked={!isExisting}
                    />
                    <span
                      className="form-label"
                      style={{
                        color: !isExisting ? "#165E5B" : "#4B4A4A",
                        fontSize: "14px",
                        fontFamily: "Segoe UI",
                        fontWeight: 500,
                      }}
                      htmlFor="inline-radio-1"
                    >
                      New Group
                    </span>
                  </label>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              {isExisting ? (
                <Form.Group className="contact-m-form">
                  <Form.Label>Choose Group</Form.Label>
                  <Select
                    style={{ marginBottom: "0" }}
                    styles={customStyles}
                    isMulti
                    className={`c-select basic-multi-select c-select-new ${
                      inputErrors.groupName.hasError ? "is-invalid" : ""
                    }`}
                    options={groups}
                    placeholder={"Select Group(s)"}
                    classNamePrefix="select"
                    onChange={(e) => {
                      console.log("eeee", e);
                      setSelectedGroups(e);
                      setInputErrors((prevState) => ({
                        ...prevState,
                        groupName: {
                          ...prevState.groupName,
                          hasError: false,
                          errorMessage: "",
                        },
                      }));
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {inputErrors.groupName.hasError &&
                      inputErrors.groupName.errorMessage}
                  </Form.Control.Feedback>
                </Form.Group>
              ) : (
                <Form.Group className="contact-m-form">
                  <Form.Label>Create Group*</Form.Label>
                  <Form.Control
                    style={{ marginBottom: "0" }}
                    type="text"
                    placeholder="MWAN Mobile"
                    name="groupName"
                    value={newContactData.groupName}
                    onChange={handleInputChange}
                    className={
                      inputErrors.groupName.hasError ? "is-invalid" : ""
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {inputErrors.groupName.hasError &&
                      inputErrors.groupName.errorMessage}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn-main-default"
            onClick={() => {
              setCtShow(false);
              setSelectedGroups([]);
              setInputErrors((prevState) => ({
                ...prevState,
                groupName: {
                  ...prevState.groupName,
                  hasError: false,
                  errorMessage: "",
                },
              }));
            }}
          >
            Cancel
          </Button>

          <Button className="btn-main" onClick={handleAddContact}>
            {isAdding ? (
              <div
                style={{
                  display: "flex",
                  minWidth: "100px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ color: "rgba(22, 94, 91, 1)" }}
                />
              </div>
            ) : (
              "Add Contact"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isInvalidRecords}
        onHide={() => setIsInvalidRecords(false)}
        className="whatsapp-modal modal-invalid"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title className="d-flex align-items-center justify-content-start w-100">
            <div
              style={{
                display: "flex",
                // flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                // padding: "0 50px",
                gap: "20px",
              }}
            >
              <img src={InvalidIcon} className="mb-2 me-3" />
              <div>
                <span className="mb-2">Import Failed</span>
                <p className="mb-2 ">
                  We cannot upload the CSV file because we have found{" "}
                  <span style={{ color: "#e94b42" }}>
                    {invalidRecordsLength}
                  </span>{" "}
                  invalid Contacts. Update the Contacts in the CSV file and
                  re-upload.
                </p>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          {inValidContacs.length > 0 && (
            <Row>
              <Col sm={12}>
                <div className="modal-table">
                  <Modaltable
                    contacts={inValidContacs}
                    setSelectedContacts={setSelectedContacts}
                    fileUploaded={fileUploaded}
                    setInvalidRecordsLength={setInvalidRecordsLength}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button className="btn-main-default w-100" onClick={handleTryAgain}>
            Try Again
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={cvsshow}
        onHide={() => setCvsShow(false)}
        className="whatsapp-modal modal-csv modal-csv-new"
        centered
      >
        <Modal.Header
          className="p-0"
          style={{ borderBottom: "2px solid #0000001A" }}
        >
          <Modal.Title className="d-flex align-items-center justify-content-between w-100">
            <span style={{ fontSize: "20px" }}>Import Contacts</span>
            <Button
              className="import-cvs"
              onClick={() => {
                setCtShow(true);
                setCvsShow(false);
              }}
            >
              Add Contact
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-4 pb-0">
          <Row>
            <Col sm={12}>
              <div className="download-template">
                <p>
                  Download the “CSV Template”, fill in the data, and then upload
                  the file to the system.
                </p>
                <Button className="btn btn-main" onClick={downloadSampleCSV}>
                  <svg
                    className="me-1"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5 10.5L10.5 14.732L14.5 10.541"
                      stroke="#165E5B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.5 3.5V14.5"
                      stroke="#165E5B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.5 17.5H16.5"
                      stroke="#165E5B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Download Sample
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <div className="Upload-cvs-file">
                  <input type="file" onChange={changeHandler} accept=".csv" />
                  <div className="file-body">
                    <i
                      class="bi bi-file-earmark-text me-2 "
                      style={{ fontSize: "20px" }}
                    ></i>
                    {fileName ? fileName : "Upload a CSV file - up to 16 MB"}
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {csvError && (
              <Row>
                <Col sm={12}>
                  <div
                    className="error-message"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "-15px",
                    }}
                  >
                    CSV file is required
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Add to*</Form.Label>
                  <div className="button-select mt-[16px]">
                    <label className="form-check form-check-inline">
                      <input
                        className="form-check-input create-template-btn"
                        type="radio"
                        id="inline-radio-1"
                        name="category"
                        value="true"
                        onChange={handleChange}
                        checked={isExisting}
                      />
                      <span
                        className="form-label"
                        style={{
                          color: isExisting ? "#165E5B" : "#4B4A4A",
                          fontSize: "14px",
                          fontFamily: "Segoe UI",
                          fontWeight: 500,
                        }}
                        htmlFor="inline-radio-1"
                      >
                        Existing Group
                      </span>
                    </label>
                    <label className="form-check form-check-inline">
                      <input
                        className="form-check-input create-template-btn"
                        type="radio"
                        id="inline-radio-1"
                        name="category"
                        value="false"
                        onChange={handleChange}
                        checked={!isExisting}
                      />
                      <span
                        className="form-label"
                        style={{
                          color: !isExisting ? "#165E5B" : "#4B4A4A",
                          fontSize: "14px",
                          fontFamily: "Segoe UI",
                          fontWeight: 500,
                        }}
                        htmlFor="inline-radio-1"
                      >
                        New Group
                      </span>
                    </label>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {isExisting ? (
                  <Form.Group className="contact-m-form">
                    <Form.Label>Choose Group</Form.Label>
                    <Select
                      styles={customStyles}
                      isMulti
                      className={`c-select basic-multi-select c-select-new ${
                        inputErrors.groupName.hasError ? "is-invalid" : ""
                      }`}
                      options={groups}
                      placeholder={"Select Group(s)"}
                      classNamePrefix="select"
                      onChange={(e) => {
                        console.log("eeee", e);
                        setSelectedGroups(e);
                        setInputErrors((prevState) => ({
                          ...prevState,
                          groupName: {
                            ...prevState.groupName,
                            hasError: false,
                            errorMessage: "",
                          },
                        }));
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {inputErrors.groupName.hasError &&
                        inputErrors.groupName.errorMessage}
                    </Form.Control.Feedback>
                  </Form.Group>
                ) : (
                  <Form.Group className="contact-m-form">
                    <Form.Label>Create Group*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="MWAN Mobile"
                      name="groupName"
                      value={newContactData.groupName}
                      onChange={handleInputChange}
                      className={
                        inputErrors.groupName.hasError ? "is-invalid" : ""
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {inputErrors.groupName.hasError &&
                        inputErrors.groupName.errorMessage}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn-main-default"
            onClick={() => {
              setCvsShow(false);
              setFileData(null);
              setFileName("");
              setSelectedGroups([]);
              setInputErrors((prevState) => ({
                ...prevState,
                groupName: {
                  ...prevState.groupName,
                  hasError: false,
                  errorMessage: "",
                },
              }));
            }}
          >
            Cancel
          </Button>
          {isAdding ? (
            <div
              style={{
                display: "flex",
                minWidth: "114.94px",
                maxWidth: "114.94px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "rgba(22, 94, 91, 1)" }}
              />
            </div>
          ) : (
            <Button className="btn-main" onClick={() => handleAddContacts()}>
              Import
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={editContact}
        onHide={() => {
          setEditContact(false);
          setSelectedContact(null);
        }}
        className="whatsapp-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Edit Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={selectedContact?.contactName}
                  name="contactName"
                  value={selectedContact?.contactName}
                  onChange={handleInputChange}
                  className={
                    inputErrors.contactName.hasError ? "is-invalid" : ""
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.contactName.hasError &&
                    inputErrors.contactName.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Phone No</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={selectedContact?.number}
                  name="number"
                  value={selectedContact?.number}
                  onChange={handleInputChange}
                  className={inputErrors.number.hasError ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.number.hasError &&
                    inputErrors.number.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group className="contact-m-form">
                <Form.Label>Choose Group</Form.Label>
                <Select
                  styles={customStyles}
                  isMulti
                  className={`c-select basic-multi-select c-select-new ${
                    inputErrors.groupName.hasError ? "is-invalid" : ""
                  }`}
                  options={groups}
                  placeholder={"Select Group(s)"}
                  classNamePrefix="select"
                  value={selectedGroups}
                  onChange={(e) => {
                    console.log("eeee", e);
                    setSelectedGroups(e);
                    setInputErrors((prevState) => ({
                      ...prevState,
                      groupName: {
                        ...prevState.groupName,
                        hasError: false,
                        errorMessage: "",
                      },
                    }));
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.groupName.hasError &&
                    inputErrors.groupName.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn-main-default"
            onClick={() => {
              setEditContact(false);
              setSelectedContact(null);
            }}
          >
            Cancel
          </Button>
          <Button className="btn-main" onClick={handleEdit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showContactSuccess}
        onHide={() => {
          setShowContactSuccess(false);
        }}
        className="whatsapp-modal modal-contact success-modal"
        centered
      >
        <Modal.Body>
          <div className="w-100 d-flex justify-content-center align-items-center flex-column text-center">
            <svg
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="34.631"
                cy="34.631"
                r="34.631"
                fill="#23A26D"
                fill-opacity="0.12"
              />
              <path
                d="M34.0124 18.1367C24.9259 18.1367 17.5215 25.5412 17.5215 34.6277C17.5215 43.7142 24.9259 51.1186 34.0124 51.1186C43.0989 51.1186 50.5034 43.7142 50.5034 34.6277C50.5034 25.5412 43.0989 18.1367 34.0124 18.1367ZM41.8951 30.8347L32.5447 40.1851C32.3139 40.416 32.0005 40.5479 31.6707 40.5479C31.3409 40.5479 31.0276 40.416 30.7967 40.1851L26.1298 35.5182C25.6515 35.0399 25.6515 34.2484 26.1298 33.7701C26.608 33.2919 27.3996 33.2919 27.8778 33.7701L31.6707 37.563L40.1471 29.0867C40.6253 28.6085 41.4169 28.6085 41.8951 29.0867C42.3733 29.5649 42.3733 30.34 41.8951 30.8347Z"
                fill="#23A26D"
              />
            </svg>

            <p
              style={{ fontSize: "24px", fontWeight: "600" }}
              className="mt-2 mb-3"
            >
              Successful
            </p>
            <p style={{ lineHeight: "24px" }}>
              <span style={{ fontSize: "16px", fontWeight: "600" }}>
                {addContactResponse?.newContact?.contactName}
              </span>{" "}
              ({addContactResponse?.newContact?.number}) is successfully added
              to “
              <span style={{ fontSize: "16px", fontWeight: "600" }}>
                {addContactResponse?.groupsInfo
                  ?.map((group) => group?.groupName)
                  ?.reduce((acc, curr, index, arr) => {
                    if (index === 0) return curr; // First element, just add it
                    if (index === arr.length - 1) return `${acc} and ${curr}`; // Add "and" before the last element
                    return `${acc}, ${curr}`; // Add a comma for other elements
                  }, "")}
              </span>
              ”{" "}
              {addContactResponse?.groupsInfo?.length == 1 ? "Group" : "Groups"}
              .
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn-main w-100"
            onClick={() => {
              setShowContactSuccess(false);
            }}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showImportSuccess}
        onHide={() => {
          setShowImportSuccess(false);
        }}
        className="whatsapp-modal modal-contact success-modal"
        centered
      >
        <Modal.Body>
          <div className="w-100 d-flex justify-content-center align-items-center flex-column text-center">
            <svg
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="34.631"
                cy="34.631"
                r="34.631"
                fill="#23A26D"
                fill-opacity="0.12"
              />
              <path
                d="M34.0124 18.1367C24.9259 18.1367 17.5215 25.5412 17.5215 34.6277C17.5215 43.7142 24.9259 51.1186 34.0124 51.1186C43.0989 51.1186 50.5034 43.7142 50.5034 34.6277C50.5034 25.5412 43.0989 18.1367 34.0124 18.1367ZM41.8951 30.8347L32.5447 40.1851C32.3139 40.416 32.0005 40.5479 31.6707 40.5479C31.3409 40.5479 31.0276 40.416 30.7967 40.1851L26.1298 35.5182C25.6515 35.0399 25.6515 34.2484 26.1298 33.7701C26.608 33.2919 27.3996 33.2919 27.8778 33.7701L31.6707 37.563L40.1471 29.0867C40.6253 28.6085 41.4169 28.6085 41.8951 29.0867C42.3733 29.5649 42.3733 30.34 41.8951 30.8347Z"
                fill="#23A26D"
              />
            </svg>

            <p
              style={{ fontSize: "24px", fontWeight: "600" }}
              className="mt-2 mb-3"
            >
              Import Successful
            </p>
            <p style={{ lineHeight: "24px" }}>
              <span style={{ fontSize: "16px", fontWeight: "600" }}>
                {importContactResponse?.duplicateNumbers?.length +
                  importContactResponse?.inserted?.length +
                  importContactResponse?.updateContacts?.length}{" "}
                contacts
              </span>{" "}
              were successfully imported into the “
              <span style={{ fontSize: "16px", fontWeight: "600" }}>
                {importContactResponse?.groupsInfo
                  ?.map((group) => group?.groupName)
                  ?.reduce((acc, curr, index, arr) => {
                    if (index === 0) return curr; // First element, just add it
                    if (index === arr.length - 1) return `${acc} and ${curr}`; // Add "and" before the last element
                    return `${acc}, ${curr}`; // Add a comma for other elements
                  }, "")}
              </span>
              ”{" "}
              {importContactResponse?.groupsInfo?.length == 1
                ? "Group"
                : "Groups"}
              .
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn-main w-100"
            onClick={() => {
              setShowImportSuccess(false);
            }}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default Contact;
