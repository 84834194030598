import React, { useState } from "react";
import { getBezierPath, useStore, BaseEdge } from "@xyflow/react";

export const getSpecialPath = (
  { sourceX, sourceY, targetX, targetY },
  offset
) => {
  const centerX = (sourceX + targetX) / 2;
  const centerY = (sourceY + targetY) / 2;

  return `M ${sourceX} ${sourceY} Q ${centerX} ${
    centerY + offset
  } ${targetX} ${targetY}`;
};

export default function CustomEdge({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  setEdges,
}) {
  const [isHovered, setIsHovered] = useState(false);

  const isBiDirectionEdge = useStore((s) => {
    const edgeExists = s.edges.some(
      (e) =>
        (e.source === target && e.target === source) ||
        (e.target === source && e.source === target)
    );

    return edgeExists;
  });

  const edgePathParams = {
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  };

  let path = "";

  if (isBiDirectionEdge) {
    path = getSpecialPath(edgePathParams, sourceX < targetX ? 25 : -25);
  } else {
    [path] = getBezierPath(edgePathParams);
  }

  // Edge Deletion Handler
  const handleDeleteEdge = () => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
  };

  // Calculate position for delete icon
  const midX = (sourceX + targetX) / 2;
  const midY = (sourceY + targetY) / 2;

  return (
    <>
      <BaseEdge
        path={path}
        markerEnd={markerEnd}
        style={{ stroke: "#333", strokeWidth: 2 }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      {isHovered && (
        <g transform={`translate(${midX - 10}, ${midY - 10})`}>
          <circle
            cx={10}
            cy={10}
            r={10}
            fill="red"
            onClick={handleDeleteEdge}
          />
          <text
            x={10}
            y={14}
            textAnchor="middle"
            fill="white"
            fontSize="12px"
            fontWeight="bold"
            style={{ cursor: "pointer" }}
            onClick={handleDeleteEdge}
          >
            X
          </text>
        </g>
      )}
    </>
  );
}
