import { configureStore } from "@reduxjs/toolkit";
import counterReucer from "./reduxSlice/counterSlice";
import MessageNotificationReducer from "./reduxSlice/messageNotificationSlice";
import ChatsReducer from "./reduxSlice/chats";
import ContactsReducer from "./reduxSlice/contacts";
import GroupsReducer from "./reduxSlice/groups";
import BroadCastsReducer from "./reduxSlice/broadcast";
import TemplatesReducer from "./reduxSlice/templates";
import CompUsersReducer from "./reduxSlice/users";
import TagsReducer from "./reduxSlice/tags";
import WorkflowReducer from "./reduxSlice/workflow";
import BillingReducer from "./reduxSlice/billing";

export const store = configureStore({
  reducer: {
    counter: counterReucer,
    MessageNotification: MessageNotificationReducer,
    ChatsReducer: ChatsReducer,
    ContactsReducer: ContactsReducer,
    GroupsReducer: GroupsReducer,
    BroadCastsReducer: BroadCastsReducer,
    TemplatesReducer: TemplatesReducer,
    CompUsersReducer: CompUsersReducer,
    TagsReducer: TagsReducer,
    WorkflowReducer: WorkflowReducer,
    BillingReducer: BillingReducer,
  },
});
