import React, { useState, useEffect, useRef } from "react";
import { Handle, NodeResizer, Position, useReactFlow } from "@xyflow/react";
import "./styles.css";
import CustomHandle from "./CustomHandle";

const AskAQuestion = ({ id, data }) => {
  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState([{ id: 1, value: "" }]);
  const [isSaved, setIsSaved] = useState(false);
  const [parentHeight, setParentHeight] = useState("auto"); // Dynamic parent height
  const [popupOpen, setPopupOpen] = useState(false);

  const parentHeightRef = useRef(null); // Reference to the parent container

  // Add new answer field
  const handleAddField = () => {
    if (answers.length < 4) {
      setAnswers([...answers, { id: answers.length + 1, value: "" }]);
    }
  };

  // Handle input change for answers
  const handleInputChange = (id, value) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.id === id ? { ...answer, value } : answer
      )
    );
  };

  const handleQuestionChange = (e) => {
    const newValue = e.target.value;
    setQuestion(newValue);
    data.updateNodeData(id, { question: newValue });
  };

  const handleCancelClick = () => {
    setAnswers([{ id: 1, value: "" }]);
    setQuestion("");
    setParentHeight("auto");
  };
  const getUniqueId = () => Math.random().toString(36).substr(2, 9);
  const handleSaveClick = () => {
    const childHeight = 39; // Height of each child input
    const gap = 20; // Gap between children
    const textareaHeight = 150; // Height of the textarea
    const headerHeight = 0; // Height of the header
    const padding = 20; // Padding for the container

    const totalHeight =
      headerHeight +
      textareaHeight +
      answers.length * (childHeight + gap) -
      gap + // Subtract the last gap
      padding * 2;

    // Update the parent div's height dynamically
    if (parentHeightRef.current) {
      parentHeightRef.current.style.height = `${totalHeight}px`;
    }

    data.updateNodeData(id, { answers });

    const children = [];
    let currentY = textareaHeight + gap; // Initial vertical position for the first child
    answers.forEach((answer) => {
      const child = {
        id: getUniqueId(),
        type: "answer",
        position: {
          x: 10,
          y: currentY,
        },
        data: { id: answer.id, value: answer.value },
        parentId: id,
        extent: "parent",
        draggable: false,
        style: {
          minWidth: "249px",
          maxHeight: `${childHeight}px`,
          padding: "0",
          background: "transparent",
        },
      };

      children.push(child);
      currentY += childHeight + gap;
    });

    data.addChildToNodes(children);
    setIsSaved(true);
  };

  const handleEditClick = () => {
    setPopupOpen(false);
    data?.deleteNode(id);
    if (parentHeightRef.current) {
      parentHeightRef.current.style.height = `550px`;
    }
    setIsSaved(false);
  };

  console.log("answers", answers);
  return (
    <>
      <div
        className="ask-a-question"
        // style={{ height: parentHeight }} // Apply dynamic height
        ref={parentHeightRef}
      >
        <div className="header">
          <h3>Ask a Question</h3>
          {popupOpen && (
            <div className="popup">
              <p onClick={handleEditClick}>Edit</p>
            </div>
          )}
          <div onClick={() => setPopupOpen(!popupOpen)}>
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="me-3 cursor-pointer"
            >
              <path
                d="M13.5 9.5C12.8125 9.5 12.224 9.27969 11.7344 8.83906C11.2448 8.39844 11 7.86875 11 7.25C11 6.63125 11.2448 6.10156 11.7344 5.66094C12.224 5.22031 12.8125 5 13.5 5C14.1875 5 14.776 5.22031 15.2656 5.66094C15.7552 6.10156 16 6.63125 16 7.25C16 7.86875 15.7552 8.39844 15.2656 8.83906C14.776 9.27969 14.1875 9.5 13.5 9.5ZM13.5 16.25C12.8125 16.25 12.224 16.0297 11.7344 15.5891C11.2448 15.1484 11 14.6187 11 14C11 13.3813 11.2448 12.8516 11.7344 12.4109C12.224 11.9703 12.8125 11.75 13.5 11.75C14.1875 11.75 14.776 11.9703 15.2656 12.4109C15.7552 12.8516 16 13.3813 16 14C16 14.6187 15.7552 15.1484 15.2656 15.5891C14.776 16.0297 14.1875 16.25 13.5 16.25ZM13.5 23C12.8125 23 12.224 22.7797 11.7344 22.3391C11.2448 21.8984 11 21.3687 11 20.75C11 20.1313 11.2448 19.6016 11.7344 19.1609C12.224 18.7203 12.8125 18.5 13.5 18.5C14.1875 18.5 14.776 18.7203 15.2656 19.1609C15.7552 19.6016 16 20.1313 16 20.75C16 21.3687 15.7552 21.8984 15.2656 22.3391C14.776 22.7797 14.1875 23 13.5 23Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        {isSaved ? (
          <div className="body-content">
            <textarea
              className="question-field mt-4"
              placeholder="Type a question"
              onChange={handleQuestionChange}
              value={question}
              disabled={true}
            ></textarea>
          </div>
        ) : (
          <div className="body-content">
            <label className="label">Question</label>
            <textarea
              className="question-field"
              placeholder="Type a question"
              onChange={handleQuestionChange}
              value={question}
            ></textarea>

            {answers.map((answer, idx) => (
              <>
                <label className="label">
                  {idx === 0
                    ? "1st Option"
                    : idx === 1
                    ? "2nd Option"
                    : "3rd Option"}
                </label>
                <div key={answer.id} className="answer-field-container">
                  <input
                    type="text"
                    className="answer_field"
                    placeholder={`Option ${idx + 1}`}
                    value={answer.value}
                    onChange={(e) =>
                      handleInputChange(answer.id, e.target.value)
                    }
                  />
                  <button className="btn" onClick={handleAddField}>
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: "23px", height: "23px" }}
                    >
                      <path
                        d="M10.5415 12.4584H5.74984C5.47831 12.4584 5.25071 12.3666 5.06702 12.1829C4.88334 11.9992 4.7915 11.7716 4.7915 11.5001C4.7915 11.2286 4.88334 11.0009 5.06702 10.8173C5.25071 10.6336 5.47831 10.5417 5.74984 10.5417H10.5415V5.75008C10.5415 5.47855 10.6333 5.25095 10.817 5.06727C11.0007 4.88359 11.2283 4.79175 11.4998 4.79175C11.7714 4.79175 11.999 4.88359 12.1827 5.06727C12.3663 5.25095 12.4582 5.47855 12.4582 5.75008V10.5417H17.2498C17.5214 10.5417 17.749 10.6336 17.9327 10.8173C18.1163 11.0009 18.2082 11.2286 18.2082 11.5001C18.2082 11.7716 18.1163 11.9992 17.9327 12.1829C17.749 12.3666 17.5214 12.4584 17.2498 12.4584H12.4582V17.2501C12.4582 17.5216 12.3663 17.7492 12.1827 17.9329C11.999 18.1166 11.7714 18.2084 11.4998 18.2084C11.2283 18.2084 11.0007 18.1166 10.817 17.9329C10.6333 17.7492 10.5415 17.5216 10.5415 17.2501V12.4584Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </>
            ))}
            <div className="save-btn-container">
              <button className="btn-cancel" onClick={handleCancelClick}>
                Cancel
              </button>
              <button className="btn-save" onClick={handleSaveClick}>
                Save
              </button>
            </div>
          </div>
        )}
      </div>

      <CustomHandle type="target" position={Position.Left} />

      {/* <CustomHandle type="source" position={Position.Right} /> */}
    </>
  );
};

export default AskAQuestion;
