import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
import AddImageIcon from "../../../../images/icons/workflow-icons/add-image-icon.svg";
import { Handle, NodeResizer, Position } from "@xyflow/react";
import {
  setId,
  setShowDocument,
  setShowImage,
  setShowMessage,
  setShowVideo,
} from "../../../../reduxSlice/workflow";
import CustomHandle from "../Ask-a-question/CustomHandle";

const SendMessage = ({ id, data }) => {
  const [base64Image, setBase64Image] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const parentState = useSelector(
    (state) => state.WorkflowReducer.parents[id] || {}
  );

  const textAreaRef = useRef(null);

  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = "auto";
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }, [message]);

  const dispatch = useDispatch();

  const handleShowImage = () => {
    dispatch(setShowImage({ id, value: true }));
    if (message) {
      setIsEdit(false);
    }
    setFile(null);
  };

  const handleShowVideo = () => {
    dispatch(setShowVideo({ id, value: true }));
    if (message) {
      setIsEdit(false);
    }
    setFile(null);
  };

  const handleShowDocument = () => {
    dispatch(setShowDocument({ id, value: true }));
    if (message) {
      setIsEdit(false);
    }
    setFile(null);
  };
  const handleShowMessage = () => {
    dispatch(setShowMessage({ id, value: true }));
  };
  const handleTextareaChange = (e) => {
    const newValue = e.target.value;
    setMessage(newValue);

    // Update the main nodes array
    const fileType = file.type.split("/")[0];

    data.updateNodeData(id, { text: newValue, url: file, type: fileType });
  };

  const renderPreview = (file) => {
    const fileType = file.type.split("/")[0];

    if (fileType === "image") {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt="Preview"
          style={{ width: "100%", margin: "12px 0" }}
        />
      );
    } else if (fileType === "video") {
      return (
        <video controls style={{ width: "100%", margin: "12px 0" }}>
          <source src={URL.createObjectURL(file)} type={file.type} />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return <p style={{ margin: "12px 0" }}>{file.name}</p>; // Show file name for documents
    }
  };

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    const fileType = selectedFile.type.split("/")[0];
    if (["image", "video", "application", "text"].includes(fileType)) {
      setFile(selectedFile);
    } else {
      alert(
        "Unsupported file type. Please upload an image, video, or document."
      );
    }

    data.updateNodeData(id, {
      text: message,
      url: selectedFile,
      type: fileType,
    });
  };

  const handleEditClick = () => {
    setPopupOpen(false);
    setIsEdit(true);

    // setIsSaved(false);
  };

  return (
    <>
      {/* <NodeResizer minWidth={360} minHeight={160} color="white" /> */}

      <div
        className="message"
        // style={{ width: width + "px", height: height + "px" }}
      >
        <div className="header">
          <h3>Send Message</h3>
          {popupOpen && (
            <div className="popup">
              <p onClick={handleEditClick}>Edit</p>
            </div>
          )}
          <div onClick={() => setPopupOpen(!popupOpen)}>
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="me-3 cursor-pointer"
            >
              <path
                d="M13.5 9.5C12.8125 9.5 12.224 9.27969 11.7344 8.83906C11.2448 8.39844 11 7.86875 11 7.25C11 6.63125 11.2448 6.10156 11.7344 5.66094C12.224 5.22031 12.8125 5 13.5 5C14.1875 5 14.776 5.22031 15.2656 5.66094C15.7552 6.10156 16 6.63125 16 7.25C16 7.86875 15.7552 8.39844 15.2656 8.83906C14.776 9.27969 14.1875 9.5 13.5 9.5ZM13.5 16.25C12.8125 16.25 12.224 16.0297 11.7344 15.5891C11.2448 15.1484 11 14.6187 11 14C11 13.3813 11.2448 12.8516 11.7344 12.4109C12.224 11.9703 12.8125 11.75 13.5 11.75C14.1875 11.75 14.776 11.9703 15.2656 12.4109C15.7552 12.8516 16 13.3813 16 14C16 14.6187 15.7552 15.1484 15.2656 15.5891C14.776 16.0297 14.1875 16.25 13.5 16.25ZM13.5 23C12.8125 23 12.224 22.7797 11.7344 22.3391C11.2448 21.8984 11 21.3687 11 20.75C11 20.1313 11.2448 19.6016 11.7344 19.1609C12.224 18.7203 12.8125 18.5 13.5 18.5C14.1875 18.5 14.776 18.7203 15.2656 19.1609C15.7552 19.6016 16 20.1313 16 20.75C16 21.3687 15.7552 21.8984 15.2656 22.3391C14.776 22.7797 14.1875 23 13.5 23Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div className="body-content">
          {parentState.showMessage && (
            <textarea
              // rows="5"
              placeholder="Write a question response here."
              onChange={handleTextareaChange}
              value={message}
              ref={textAreaRef}
              style={{ resize: "none", overflow: "hidden", minHeight: "100px" }}
              onWheel={(e) => {
                e.stopPropagation();
              }}
            ></textarea>
          )}
          {parentState.showImage &&
          !parentState.showVideo &&
          !parentState.showDocument ? (
            file && parentState.showImage ? (
              renderPreview(file)
            ) : (
              <div className="add-media-container">
                <svg
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91667 33.25C7.04583 33.25 6.30035 32.9399 5.68021 32.3198C5.06007 31.6997 4.75 30.9542 4.75 30.0833V7.91667C4.75 7.04583 5.06007 6.30035 5.68021 5.68021C6.30035 5.06007 7.04583 4.75 7.91667 4.75H30.0833C30.9542 4.75 31.6997 5.06007 32.3198 5.68021C32.9399 6.30035 33.25 7.04583 33.25 7.91667V30.0833C33.25 30.9542 32.9399 31.6997 32.3198 32.3198C31.6997 32.9399 30.9542 33.25 30.0833 33.25H7.91667ZM11.0833 26.9167H26.9167C27.2333 26.9167 27.4708 26.7715 27.6292 26.4812C27.7875 26.191 27.7611 25.9139 27.55 25.65L23.1958 19.8313C23.0375 19.6201 22.8264 19.5146 22.5625 19.5146C22.2986 19.5146 22.0875 19.6201 21.9292 19.8313L17.8125 25.3333L14.8833 21.4146C14.725 21.2035 14.5139 21.0979 14.25 21.0979C13.9861 21.0979 13.775 21.2035 13.6167 21.4146L10.45 25.65C10.2389 25.9139 10.2125 26.191 10.3708 26.4812C10.5292 26.7715 10.7667 26.9167 11.0833 26.9167Z"
                    fill="#165E5B"
                  />
                </svg>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileUpload}
                  id="upload-input"
                  style={{ display: "none" }}
                />
                <button
                  className="add-btn"
                  onClick={() =>
                    document.getElementById("upload-input").click()
                  }
                >
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.5 10.5H15.5"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.5 5.5V15.5"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Add Image
                </button>
              </div>
            )
          ) : parentState.showVideo &&
            !parentState.showImage &&
            !parentState.showDocument ? (
            file ? (
              renderPreview(file)
            ) : (
              <div className="add-media-container">
                <svg
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91667 33.25C7.04583 33.25 6.30035 32.9399 5.68021 32.3198C5.06007 31.6997 4.75 30.9542 4.75 30.0833V7.91667C4.75 7.04583 5.06007 6.30035 5.68021 5.68021C6.30035 5.06007 7.04583 4.75 7.91667 4.75H30.0833C30.9542 4.75 31.6997 5.06007 32.3198 5.68021C32.9399 6.30035 33.25 7.04583 33.25 7.91667V30.0833C33.25 30.9542 32.9399 31.6997 32.3198 32.3198C31.6997 32.9399 30.9542 33.25 30.0833 33.25H7.91667ZM11.0833 26.9167H26.9167C27.2333 26.9167 27.4708 26.7715 27.6292 26.4812C27.7875 26.191 27.7611 25.9139 27.55 25.65L23.1958 19.8313C23.0375 19.6201 22.8264 19.5146 22.5625 19.5146C22.2986 19.5146 22.0875 19.6201 21.9292 19.8313L17.8125 25.3333L14.8833 21.4146C14.725 21.2035 14.5139 21.0979 14.25 21.0979C13.9861 21.0979 13.775 21.2035 13.6167 21.4146L10.45 25.65C10.2389 25.9139 10.2125 26.191 10.3708 26.4812C10.5292 26.7715 10.7667 26.9167 11.0833 26.9167Z"
                    fill="#165E5B"
                  />
                </svg>
                <input
                  type="file"
                  accept="video/*"
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                  id="upload-input"
                />
                <button
                  className="add-btn"
                  onClick={() =>
                    document.getElementById("upload-input").click()
                  }
                >
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.5 10.5H15.5"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.5 5.5V15.5"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Add Video
                </button>
              </div>
            )
          ) : parentState.showDocument &&
            !parentState.showImage &&
            !parentState.showVideo ? (
            file ? (
              renderPreview(file)
            ) : (
              <div className="add-media-container">
                <svg
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7501 25.4999H21.2501C21.6515 25.4999 21.9879 25.3642 22.2595 25.0926C22.531 24.8211 22.6667 24.4846 22.6667 24.0833C22.6667 23.6819 22.531 23.3454 22.2595 23.0739C21.9879 22.8023 21.6515 22.6666 21.2501 22.6666H12.7501C12.3487 22.6666 12.0122 22.8023 11.7407 23.0739C11.4692 23.3454 11.3334 23.6819 11.3334 24.0833C11.3334 24.4846 11.4692 24.8211 11.7407 25.0926C12.0122 25.3642 12.3487 25.4999 12.7501 25.4999ZM12.7501 19.8333H21.2501C21.6515 19.8333 21.9879 19.6975 22.2595 19.426C22.531 19.1544 22.6667 18.818 22.6667 18.4166C22.6667 18.0152 22.531 17.6787 22.2595 17.4072C21.9879 17.1357 21.6515 16.9999 21.2501 16.9999H12.7501C12.3487 16.9999 12.0122 17.1357 11.7407 17.4072C11.4692 17.6787 11.3334 18.0152 11.3334 18.4166C11.3334 18.818 11.4692 19.1544 11.7407 19.426C12.0122 19.6975 12.3487 19.8333 12.7501 19.8333ZM8.50008 31.1666C7.72091 31.1666 7.0539 30.8892 6.49904 30.3343C5.94418 29.7794 5.66675 29.1124 5.66675 28.3333V5.66659C5.66675 4.88742 5.94418 4.2204 6.49904 3.66554C7.0539 3.11068 7.72091 2.83325 8.50008 2.83325H18.6647C19.0424 2.83325 19.4025 2.90409 19.7449 3.04575C20.0872 3.18742 20.3883 3.38811 20.648 3.64784L27.5188 10.5187C27.7786 10.7784 27.9792 11.0794 28.1209 11.4218C28.2626 11.7642 28.3334 12.1242 28.3334 12.502V28.3333C28.3334 29.1124 28.056 29.7794 27.5011 30.3343C26.9463 30.8892 26.2792 31.1666 25.5001 31.1666H8.50008ZM18.4167 11.3333V5.66659H8.50008V28.3333H25.5001V12.7499H19.8334C19.432 12.7499 19.0956 12.6142 18.824 12.3426C18.5525 12.0711 18.4167 11.7346 18.4167 11.3333Z"
                    fill="#165E5B"
                  />
                </svg>
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.txt"
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                  id="upload-input"
                />
                <button
                  className="add-btn"
                  onClick={() =>
                    document.getElementById("upload-input").click()
                  }
                >
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mb-1 me-2"
                  >
                    <path
                      d="M12.079 5.85L8.51977 2.25L4.92114 5.85"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.51978 2.2688V13.0499"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.23682 9.8999V13.9499C2.23682 14.944 3.03799 15.7499 4.02629 15.7499H12.9737C13.962 15.7499 14.7631 14.944 14.7631 13.9499V9.8999"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Upload Document
                </button>
              </div>
            )
          ) : null}

          {(!message || !file || isEdit) && (
            <div className="buttons">
              <button
                className="btn"
                onClick={() => {
                  // data.handleAddChild(id);
                  handleShowMessage();
                }}
              >
                Message
              </button>
              <button
                className="btn"
                onClick={() => {
                  handleShowImage();
                }}
              >
                Image
              </button>
              <button
                className="btn"
                onClick={() => {
                  handleShowVideo();
                }}
              >
                Video
              </button>
              <button
                className="btn"
                onClick={() => {
                  handleShowDocument();
                }}
              >
                Document
              </button>
            </div>
          )}
        </div>
      </div>
      {/* <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
       */}
      <CustomHandle type="target" position={Position.Left} />

      {/* <CustomHandle type="source" position={Position.Right} /> */}
    </>
  );
};

export default SendMessage;
