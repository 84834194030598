import React, { useState, useEffect } from "react";
import "./styles.css";
import { formatCostByCountry } from "../../utils/billingUtils";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalCategoryCost } from "../../reduxSlice/billing";

const Card2Content = ({ billingData }) => {
  const dispatch = useDispatch();
  console.log("card2Cpontent", billingData);
  const { currency } = useSelector((state) => state.BillingReducer);
  const [categoryCost, setCategoryCost] = useState({
    marketing: 0,
    utility: 0,
    authentication: 0,
    services: 0,
    totalMessages: 0,
    overallTotalCost: 0,
  });

  const [totalCost, setTotalCost] = useState(0);
  // const calculateCategoryCost = () => {
  //   let marketingCost = 0,
  //     utilityCost = 0,
  //     authenticationCost = 0,
  //     servicesCost = 0,
  //     totalCost = 0;

  //   let marketingCountry = "",
  //     utilityCountry = "",
  //     authenticationCountry = "",
  //     servicesCountry = "";

  //   billingData?.forEach((data) => {
  //     const cost = parseFloat(data?.cost) || 0; // Ensure the cost is a valid number
  //     if (data?.conversation_category === "MARKETING") {
  //       marketingCost += cost;
  //       marketingCountry = data?.country || marketingCountry;
  //       if (cost > 0) {
  //         totalCost += 1;
  //       }
  //     } else if (data?.conversation_category === "UTILITY") {
  //       utilityCost += cost;
  //       utilityCountry = data?.country || utilityCountry;
  //       if (cost > 0) {
  //         totalCost += 1;
  //       }
  //     } else if (data?.conversation_category === "AUTHENTICATION") {
  //       authenticationCost += cost;
  //       authenticationCountry = data?.country || authenticationCountry;
  //       if (cost > 0) {
  //         totalCost += 1;
  //       }
  //     } else if (data?.conversation_category === "SERVICES") {
  //       servicesCost += cost;
  //       servicesCountry = data?.country || servicesCountry;
  //       if (cost > 0) {
  //         totalCost += 1;
  //       }
  //     }
  //   });
  //   // Add debugging to check if utility cost is being calculated

  //   const formatedUtilityCost = formatCostByCountry(
  //     utilityCountry || "PK",
  //     utilityCost
  //   );
  //   console.log("Utility Cost formated:", formatedUtilityCost); // Check if utility cost is being added

  //   setCategoryCost({
  //     marketing: formatCostByCountry(marketingCountry || "PK", marketingCost),
  //     utility: formatedUtilityCost,
  //     authentication: formatCostByCountry(
  //       authenticationCountry || "PK",
  //       authenticationCost
  //     ),
  //     services: formatCostByCountry(servicesCountry || "PK", servicesCost),
  //   });
  // };

  const calculateCategoryCost = () => {
    // Initialize category-related variables
    const categoryData = {
      MARKETING: { cost: 0, country: "" },
      UTILITY: { cost: 0, country: "" },
      AUTHENTICATION: { cost: 0, country: "" },
      SERVICES: { cost: 0, country: "" },
    };

    const totalMessages = billingData?.reduce((sum, { cost, conversation }) => {
      const parsedCost = parseFloat(cost) || 0; // Ensure cost is numeric
      return parsedCost > 0 ? sum + (parseInt(conversation, 10) || 0) : sum;
    }, 0);
    let countryCurrency = "";
    // Iterate over billingData to calculate costs
    billingData?.forEach(({ conversation_category, cost, country }) => {
      const parsedCost = parseFloat(cost) || 0; // Ensure valid numeric cost

      if (categoryData[conversation_category]) {
        const category = categoryData[conversation_category];
        category.cost += parsedCost;
        category.country = country || category.country;
      }
      countryCurrency = country;
    });

    const overallTotalCost = Object.values(categoryData).reduce(
      (sum, category) => {
        return sum + category.cost;
      },
      0
    );
    const vatCost = (overallTotalCost * 5) / 100;
    const totalCostIncludingVat = vatCost + overallTotalCost;
    // Helper function to format costs by country
    const formatCategoryCost = (categoryKey, defaultCountry) => {
      const { cost } = categoryData[categoryKey];
      return formatCostByCountry(defaultCountry, cost);
    };

    // Set formatted category costs
    setCategoryCost({
      marketing: formatCategoryCost("MARKETING", currency?.currency),
      utility: formatCategoryCost("UTILITY", currency?.currency),
      authentication: formatCategoryCost("AUTHENTICATION", currency?.currency),
      services: formatCategoryCost("SERVICES", currency?.currency),
      totalMessages,
      vat: formatCostByCountry(currency?.currency, vatCost),
      overallTotalCost: formatCostByCountry(
        currency?.currency,
        totalCostIncludingVat
      ),
    });
  };

  useEffect(() => {
    setTotalCost();
  }, [categoryCost]);

  useEffect(() => {
    if (billingData?.length) {
      calculateCategoryCost();
    }
  }, [billingData]);
  console.log("cost", categoryCost);

  return (
    <div className="card2-content">
      <div className="row1 head">
        <h3 className="title">Paid Messages</h3>
        <p className="value">{categoryCost.totalMessages}</p>
      </div>
      <div className="row1">
        <p className="title">Marketing</p>
        <p className="value">{categoryCost.marketing}</p>
      </div>
      <div className="row1">
        {" "}
        <p className="title">Utility</p>
        <p className="value">{categoryCost.utility}</p>
      </div>
      <div className="row1">
        {" "}
        <p className="title">Authentication</p>
        <p className="value">{categoryCost.authentication}</p>
      </div>
      <div className="row1">
        {" "}
        <p className="title">Services</p>
        <p className="value">{categoryCost.services}</p>
      </div>
      <hr />
      <div className="mt-3 d-flex justify-content-between">
        {" "}
        <p className="title">VAT 5%</p>
        <p className="value">{categoryCost.vat}</p>
      </div>
      <div className="mt-3 d-flex justify-content-between">
        {" "}
        <p className="title">Total Cost</p>
        <p className="value">{categoryCost.overallTotalCost}</p>
      </div>
    </div>
  );
};

export default Card2Content;
