import React, { useState, useRef, useEffect } from "react";

const CustomSelect = ({
  placeholder,
  options,
  value,
  onChange,
  backgroundHoverColor,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const truncateString = (str) => {
    if (str.length <= 22) {
      return str;
    }
    return str.slice(0, 23) + "...";
  };

  console.log("selectedOption", options);
  return (
    <div className="custom-select-wrapper">
      <div className="custom-select" onClick={handleOpen}>
        <p className="select-placeholder">{value?.label || placeholder}</p>
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.0009 11.2896L14.5759 7.8771C14.744 7.71668 14.9579 7.63647 15.2176 7.63647C15.4773 7.63647 15.6912 7.71668 15.8592 7.8771C16.0273 8.03752 16.1113 8.24168 16.1113 8.4896C16.1113 8.73752 16.0273 8.94168 15.8592 9.1021L11.6426 13.1271C11.5509 13.2146 11.4516 13.2766 11.3447 13.313C11.2377 13.3495 11.1231 13.3677 11.0009 13.3677C10.8787 13.3677 10.7641 13.3495 10.6572 13.313C10.5502 13.2766 10.4509 13.2146 10.3592 13.1271L6.14258 9.1021C5.97452 8.94168 5.8905 8.73752 5.8905 8.4896C5.8905 8.24168 5.97452 8.03752 6.14258 7.8771C6.31063 7.71668 6.52452 7.63647 6.78425 7.63647C7.04397 7.63647 7.25786 7.71668 7.42591 7.8771L11.0009 11.2896Z"
            fill="#4B4A4A"
          />
        </svg>
      </div>
      <div className={`options ${isOpen ? "open" : ""}`}>
        {options.map((option, index) => (
          <>
            <p
              key={index}
              onClick={() => {
                onChange(option);
                setIsOpen(false);
              }}
            >
              {truncateString(option.label)}
            </p>
            {options.length - 1 != index && <hr />}
          </>
        ))}
      </div>
    </div>
  );
};

export default CustomSelect;
