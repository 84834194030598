import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  billingData: [],
  loading: false,
  isMonthSelected: "DAILY",
  globalCategoryCost: {},
  currency: {},
};

export const BillingSlice = createSlice({
  name: "Billing",
  initialState,
  reducers: {
    setBillingData: (state, action) => {
      state.billingData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsMonthSelected: (state, action) => {
      state.isMonthSelected = action.payload;
    },
    setGlobalCategoryCost: (state, action) => {
      state.isMonthSelected = action.payload;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBillingData,
  setLoading,
  setIsMonthSelected,
  setGlobalCategoryCost,
  setCurrency,
} = BillingSlice.actions;

export default BillingSlice.reducer;
