import React from "react";
import SendMessageIcon from "../../../images/icons/workflow-icons/send-message-icon.svg";
import QuestionIcon from "../../../images/icons/workflow-icons/ask-a-question.svg";
import TemplateIcon from "../../../images/icons/workflow-icons/template-icon.svg";
import FormIcon from "../../../images/icons/workflow-icons/form-icon.svg";
import GroupNode from "./GroupNode";

const Sidebar = () => {
  const groupNodes = [
    {
      id: "sendMessage",
      title: "Send Message",
      description: "With no response required from visitor.",
      icon: SendMessageIcon,
      color: "#31B58D",
    },
    {
      id: "askAQuestion",
      title: "Ask a Question",
      description: "Ask questions to get user's response.",
      icon: QuestionIcon,
      color: "#D1852B",
    },
    {
      id: "template",
      title: "Template",
      description: "Select Template that you have added.",
      icon: TemplateIcon,
      color: "#578AE4",
    },
    {
      id: "form",
      title: "Form",
      description: "Get user's response by adding a form.",
      icon: FormIcon,
      color: "#6B2484",
    },
  ];

  const groupNodeType = "group";

  const handleDragStart = (event, nodeType, node) => {
    event.dataTransfer.setData("nodeType", nodeType);
    event.dataTransfer.setData("nodeData", JSON.stringify(node));
  };

  return (
    <div className="sidebar">
      <h2>Select Message Type</h2>

      {groupNodes.map((node) => {
        return (
          <GroupNode
            id={node.id}
            draggable
            onDragStart={(event) => handleDragStart(event, groupNodeType, node)}
            title={node.title}
            description={node.description}
            icon={node.icon}
            colour={node.color}
          />
        );
      })}
    </div>
  );
};

export default Sidebar;
