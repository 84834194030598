import { useCallback, useState, useRef } from "react";
import {
  ReactFlow,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  useReactFlow,
  ReactFlowProvider,
  MiniMap,
  Controls,
} from "@xyflow/react";

import "../flows.css";
import SendMessage from "./send-message/SendMessage";
import AskAQuestion from "./Ask-a-question/AskAQuestion";
import Answer from "./Ask-a-question/Answer";
import Template from "./Template/Template";
import TemplatePreview from "./Template/TemplatePreview";
import Form from "./Form/Form";
import SubmitForm from "./Form/SubmitForm";
import { useDispatch } from "react-redux";
import { setGLobalNodes } from "../../../reduxSlice/workflow";
import CustomEdge from "./CustomEdge";

const initialEdges = [];
const initialNodes = [];

const nodeTypes = {
  sendMessage: SendMessage,
  askAQuestion: AskAQuestion,
  answer: Answer,
  template: Template,
  // templatePreview: TemplatePreview,
  form: Form,
  submitForm: SubmitForm,
};

const FlowCanvas = () => {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [draggedNodeData, setDraggedData] = useState(null);
  const { screenToFlowPosition } = useReactFlow();
  const [panPosition, setPanPosition] = useState({ x: 0, y: 0 });
  const flowRef = useRef(null);
  const edgeTypes = {
    bidirectional: (props) => <CustomEdge {...props} setEdges={setEdges} />,
  };
  const dispatch = useDispatch();
  // Handle node updates
  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  // const onEdgesChange = useCallback(
  //   (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
  //   [setEdges]
  // );

  const onEdgesChange = useCallback(
    (changes) =>
      setEdges((eds) =>
        applyEdgeChanges(changes, eds).map((edge) => ({
          ...edge,
          type: edge.type || "bidirectional", // Ensure the type persists
        }))
      ),
    [setEdges]
  );

  const onConnect = useCallback((connection) => {
    const edge = { ...connection, type: "bidirectional", animated: true };
    setEdges((eds) => addEdge(edge, eds));
  }, []);

  const handleMove = (event, viewport) => {
    setPanPosition({ x: viewport.x, y: viewport.y });
  };
  const nodeClassName = (node) => node.type;
  const updateNodeData = (nodeId, newData) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? { ...node, data: { ...node.data, ...newData } }
          : node
      )
    );
  };
  const addChildToNodes = (children) => {
    console.log("nodes:data", children);
    setNodes((prevNodes) => [
      ...prevNodes,
      ...children.map((child) => ({ ...child })),
    ]);
  };
  const addSingleChildToNode = (child) => {
    console.log("child", child);
    setNodes((prevNodes) => [...prevNodes, child]);
  };

  const deleteNode = (parentId) => {
    setNodes((prevNodes) =>
      prevNodes.filter((node) => node.parentId !== parentId)
    );
  };
  // Handle drop event
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      // Get the mouse position on the screen
      const mousePosition = {
        x: event.clientX,
        y: event.clientY,
      };
      const flowPosition = screenToFlowPosition(mousePosition);
      const nodeType = event.dataTransfer.getData("nodeType");
      console.log("event");
      const nodeData = event.dataTransfer.getData("nodeData");
      const parsedData = JSON.parse(nodeData);
      setDraggedData(parsedData);

      console.log("parsedData", parsedData);
      if (nodeType === "group" && parsedData.id == "sendMessage") {
        // Create a group node
        const newGroupNode = {
          id: `${+new Date()}`,
          type: "sendMessage",
          data: {
            handleAddChild,
            draggedNodeData,

            text: "",
            updateNodeData,
            url: "",
            type: "",
          },
          position: flowPosition,
          // style: {
          //   minWidth: "360px",
          //   minHeight: "150px",
          //   background: "white",
          //   border: "none",
          //   outline: "0",
          // },
        };

        setNodes((prevNodes) => [...prevNodes, newGroupNode]);
      } else if (nodeType === "group" && parsedData.id == "askAQuestion") {
        const newGroupNode = {
          id: `${+new Date()}`,
          type: "askAQuestion",

          data: {
            updateNodeData,
            question: "",
            answers: [],
            addChildToNodes,
            deleteNode,
          },
          position: flowPosition,

          // position,
          // style: {
          //   // minWidth: "360px",
          //   // minHeight: "150px",
          //   // background: "white",
          //   border: "none",
          //   outline: "0",
          // },
        };

        setNodes((prevNodes) => [...prevNodes, newGroupNode]);
      } else if (nodeType === "group" && parsedData.id == "template") {
        const newGroupNode = {
          id: `${+new Date()}`,
          type: "template",
          data: { addSingleChildToNode, templatetoShow: {}, updateNodeData },
          position: flowPosition,
        };

        setNodes((prevNodes) => [...prevNodes, newGroupNode]);
      } else if (nodeType === "group" && parsedData.id == "form") {
        const newGroupNode = {
          id: `${+new Date()}`,
          type: "form",
          data: {
            addSingleChildToNode,
            updateNodeData,
            deleteNode,
          },
          position: flowPosition,
          style: { zIndex: "100" },
        };

        setNodes((prevNodes) => [...prevNodes, newGroupNode]);
      } else {
        const newNode = {
          id: `${+new Date()}`,
          type: nodeType,
          position: flowPosition,
          data: { label: nodeType },
        };

        setNodes((prevNodes) => [...prevNodes, newNode]);
      }
    },
    [nodes]
  );

  const handleAddChild = (parentId) => {
    setNodes((prevNodes) => {
      const parentNode = prevNodes.find((node) => node.id === parentId);
      const existingChildren = prevNodes.filter(
        (node) => node.parentId === parentId
      );

      const childHeight = 60; // Fixed height for each child node
      const childSpacing = 10; // Spacing between child nodes

      const initialYOffset = 250; // Offset for where "Add Child" button ends

      const newChildPosition = {
        x: 10, // Offset slightly to the right (relative to parent)
        y:
          initialYOffset +
          existingChildren.length * (childHeight + childSpacing), // Start below button
      };
      const childPosition = { x: 0, y: 100 };

      const newChildNode = {
        id: `${+new Date()}`,
        type: "child",
        position: childPosition,
        data: { label: `Child Node ${existingChildren.length + 1}` },
        parentId: parentId,
        extent: "parent", // Keep children inside parent
        style: {
          minWidth: "100px",
          minHeight: "50px",
          padding: "0",
        },
      };

      return [...prevNodes, newChildNode];
    });
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);
  console.log("nodes: ", nodes, ":: ", "edges: ", edges);

  const handleSaveFlow = () => {
    const payload = {
      nodes,
      edges,
    };

    const flowName = "test-flow";
    const flowCategory = "OTHER";

    const data = {
      payload,
      name: flowName,
      category: flowCategory,
    };
    console.log("submit flow", data);
  };

  return (
    <div className="flow-canvas" onDrop={onDrop} onDragOver={onDragOver}>
      <div className="top-bar">
        <p className="workflow-name">WorkFlow</p>
        <button className="workflow-save" onClick={handleSaveFlow}>
          {" "}
          Save Changes{" "}
        </button>
      </div>
      <ReactFlow
        ref={flowRef}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
        attributionPosition="top-right"
        edgeTypes={{
          ...edgeTypes,
          animated: true,
          type: "smoothstep",
          style: {
            stroke: "#333",
            strokeWidth: 2,
            fill: "none",
            dasharray: "5, 5",
          },
        }}
        // zoomOnScroll={false}
        onMove={handleMove} // onMove to capture pan position
      >
        <MiniMap />
        <Controls />
        <Background variant="lines" gap={65} color="#E3E8EF" />
      </ReactFlow>
    </div>
  );
};

export default FlowCanvas;
