import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allGroups: [],
  totalRecords: 0,
  allGroupsFilter: [],
  selectedFilterGroup: null,
};

export const GroupsSlices = createSlice({
  name: "Groups",
  initialState,
  reducers: {
    // set all groups
    setGroupsData: (state, action) => {
      state.allGroups = Object.values(action.payload.data);
      state.totalRecords = action.payload.totalRecords;
    },
    // empty all groups
    removeAllGroupsData: (state, action) => {
      state.allGroups = [];
      state.totalRecords = 0;
    },
    setAllGroups: (state, action) => {
      state.allGroupsFilter = action.payload;
    },
    setSelectedFilterGroup: (state, action) => {
      state.selectedFilterGroup = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setGroupsData,
  removeAllGroupsData,
  setAllGroups,
  setSelectedFilterGroup,
} = GroupsSlices.actions;

export default GroupsSlices.reducer;
