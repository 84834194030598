// export const formatTimestampToDate = (timestamp) => {
//   // Ensure timestamp is in milliseconds
//   const date = new Date(timestamp < 1e12 ? timestamp * 1000 : timestamp);

//   // Extract day, month, and year in UTC
//   const parts = date.toUTCString().split(" ");
//   const utcFormattedDate = `${parts[2]} ${parts[1]} ${parts[3]}`; // "Jan 07 2025"

//   return utcFormattedDate;
// };
export const formatTimestampToDateMonthly = (timestamp) => {
  // Convert timestamp to milliseconds and create a Date object
  const date = new Date(timestamp * 1000);

  // Format the date to "Feb 2024"
  const formattedDate = date
    .toLocaleString("en-US", {
      month: "short",
      year: "numeric",
    })
    .replace(",", ""); // Remove the comma

  return formattedDate;
};
export function formatTimestampToDate(ms) {
  const date = new Date(ms * 1000); // Convert seconds to milliseconds
  return date
    .toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    })
    .replace(",", "");
}

function getCurrencySymbol(currencyCode) {
  return new Intl.NumberFormat("en", {
    style: "currency",
    currency: currencyCode,
  })
    .formatToParts(1)
    .find((part) => part.type === "currency").value;
}

export const formatCostByCountry = (country, cost) => {
  // Map of country codes to currency symbols

  // Get the currency symbol for the given country
  const symbol = (country && getCurrencySymbol(country)) || "$"; // Default to empty if country not found

  // Convert cost to non-floating (integer)
  const formattedCost = parseFloat(cost).toFixed(3);

  console.log("formattedCost", typeof formattedCost);

  // Return formatted cost with the symbol
  return `${symbol} ${formattedCost}`;
};
