import React, { useEffect, useState, useRef } from "react";
import { AuthTemplate } from "../../../template-preview/AutheTemplate";
import { UrlPhoneNumber } from "../../../template-preview/UrlPhoneNumber";
import { QuickReplyPreview } from "../../../template-preview/QuickReplyPreview";
import CustomHandle from "../Ask-a-question/CustomHandle";
import { Position } from "@xyflow/react";

const TemplatePreview = ({ templatetoShow, id, data }) => {
  return (
    <>
      <div className="template-preview">
        <div className="preview-template-inbox">
          {data?.templatetoShow?.category == "AUTHENTICATION" ? (
            <AuthTemplate
              components={data?.templatetoShow?.components}
              isInbox={true}
            />
          ) : (
            <UrlPhoneNumber
              components={data?.templatetoShow?.components}
              isInbox={true}
              template={data?.templatetoShow}
              id={data?.templatetoShow?.id}
            />
          )}
        </div>
        <QuickReplyPreview components={data?.templatetoShow?.components} />
      </div>
      <CustomHandle type="target" position={Position.Left} />

      {/* <CustomHandle type="source" position={Position.Right} /> */}
    </>
  );
};

export default TemplatePreview;
