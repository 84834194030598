import React from "react";
import useDeviceWidthChecker from "../customHooks/DeviceWidthDetector";

import "./styles.css";

const SkeletonLoader = () => {
  const screenWidth = useDeviceWidthChecker();

  return (
    <div className="skeleton-container container">
      {screenWidth > 768 ? (
        <>
          {" "}
          {/* Billing Summary Section */}
          <div className="skeleton-billing-summary">
            <div className="skeleton-header">
              <div className="skeleton-heading"></div>
              <div className="skeleton-range-picker"></div>
            </div>
            <div className="skeleton-table">
              {[...Array(7)].map((_, index) => (
                <div key={index} className="skeleton-table-row">
                  <div className="skeleton-table-cell date"></div>
                  <div className="skeleton-table-cell messages"></div>
                  <div className="skeleton-table-cell amount"></div>
                </div>
              ))}
            </div>
          </div>
          {/* Right Section (Messages and Paid Messages) */}
          <div className="skeleton-right">
            {/* <div className="skeleton-chart-card">
              <div className="skeleton-chart"></div>
              <div className="skeleton-chart-details"></div>
            </div> */}
            <div className="skeleton-paid-messages">
              {[...Array(6)].map((_, index) => (
                <div key={index} className="skeleton-paid-row">
                  <div className="skeleton-paid-category"></div>
                  <div className="skeleton-paid-amount"></div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          {/* Right Section (Messages and Paid Messages) */}
          <div className="skeleton-right">
            {/* <div className="skeleton-chart-card">
              <div className="skeleton-chart"></div>
              <div className="skeleton-chart-details"></div>
            </div> */}
            <div className="skeleton-paid-messages">
              {[...Array(6)].map((_, index) => (
                <div key={index} className="skeleton-paid-row">
                  <div className="skeleton-paid-category"></div>
                  <div className="skeleton-paid-amount"></div>
                </div>
              ))}
            </div>
          </div>
          {/* Billing Summary Section */}
          <div className="skeleton-billing-summary">
            <div className="skeleton-header">
              <div className="skeleton-heading"></div>
              <div className="skeleton-range-picker"></div>
            </div>
            <div className="skeleton-paid-messages my-3">
              {[...Array(6)].map((_, index) => (
                <div key={index} className="skeleton-paid-row">
                  <div className="skeleton-paid-category"></div>
                  <div className="skeleton-paid-amount"></div>
                </div>
              ))}
            </div>
            <div className="skeleton-paid-messages">
              {[...Array(6)].map((_, index) => (
                <div key={index} className="skeleton-paid-row">
                  <div className="skeleton-paid-category"></div>
                  <div className="skeleton-paid-amount"></div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SkeletonLoader;
