import React, { useState } from "react";
import { Pagination } from "antd";

const ResponsiveBillingTable = ({ billingDetail, columns }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Calculate the data for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = billingDetail?.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  console.log("currentData", currentData);
  return (
    <>
      {" "}
      {currentData?.length <= 0 || !currentData ? (
        <div
          style={{
            width: "100%",
            height: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>No Data Found</h2>
        </div>
      ) : (
        currentData?.map((billing) => {
          return (
            <div className="responsive-card">
              <div className="date">{billing?.date}</div>
              <div className="responsive-content">
                <div className="responsive-title">Amount</div>
                <div className="responsive-value">{billing?.amount}</div>
              </div>
              <div className="responsive-content">
                <div className="responsive-title">Total Message</div>
                <div className="responsive-value">{billing?.totalMessages}</div>
              </div>
              <div className="responsive-content">
                <div className="responsive-title">Category</div>
                <div className="responsive-value">{billing?.category}</div>
              </div>
            </div>
          );
        })
      )}
      {/* Pagination Component */}
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Pagination
          total={billingDetail?.length} // Total number of items
          pageSize={itemsPerPage} // Items per page
          current={currentPage} // Current page
          onChange={handlePageChange} // Change page handler
          //   showSizeChanger={false} // Disable size changer
          //   showQuickJumper={true} // Allow quick jump to a page
          showQuickJumper
        />
      </div>
    </>
  );
};

export default ResponsiveBillingTable;
