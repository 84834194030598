import React from "react";
import Sidebar from "../components/whatsapp-flows/Sidebar/Sidebar";
import FlowCanvas from "../components/whatsapp-flows/Workflow/FlowCanvas";
import "@xyflow/react/dist/style.css";
import {
  ReactFlow,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  useReactFlow,
  ReactFlowProvider,
} from "@xyflow/react";

const Workflow = () => {
  return (
    <div className="app-container">
      <Sidebar />
      <ReactFlowProvider>
        <FlowCanvas />
      </ReactFlowProvider>
    </div>
  );
};

export default Workflow;
