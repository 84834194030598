import React from "react";

import "./group-contact.css";
import Username from "../userName/username";

const Groupcontact = ({ Image, name, handleCreateChat, id, index }) => {
  return (
    <div>
      <ul className="nav align-items-center">
        <li className="nav-item">
          <div className="user-img" style={{ marginTop: "8px" }}>
            <Username nameletter={name} index={index} />
          </div>
        </li>
        <li className="nav-item">
          <div className="u-name">{name}</div>
        </li>
      </ul>
    </div>
  );
};

export default Groupcontact;
